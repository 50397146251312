import React from 'react';

import { Link } from '@mattr/shared-components';

import { filterConnector } from './filter-connector';

export const ViewMore = filterConnector(
  ({ query }): JSX.Element => {
    return (
      <Link className="mt-1.5 font-semibold hover:underline hover:text-blue-500" to={`/search?query=${query}`}>
        View more →
      </Link>
    );
  },
);
