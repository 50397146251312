import { Link } from '@mattr/shared-components';
import React, { ClassAttributes, useState } from 'react';
import { useScrollRestoration } from 'gatsby';

import { sortByPosition } from '../utils';

const isSideBarItem = (item: SideBarItem | SubItem): item is SideBarItem => {
  return (item as SideBarItem).treeChildren !== undefined;
};

const SideBarItem = (props: (SideBarItem | SubItem) & { children?: React.ReactNode }) => {
  const { hasActiveChildren, showInNavigation, children, title, shortTitle, slug, pageSlug } = props;

  let treeChildren: SubItems | null = null;
  if (isSideBarItem(props)) {
    treeChildren = props.treeChildren;
  }
  const [isCollapsed, setIsCollapsed] = useState(!hasActiveChildren);
  const isActiveLink = pageSlug === slug;
  const itemClassName = `${isActiveLink || hasActiveChildren ? 'sidebar-link sidebar-link--active' : 'sidebar-link'}`;
  const hasChildren = treeChildren?.some((child: SubItem) => !!child.showInNavigation);

  if (!showInNavigation) return null;

  if (!hasChildren) {
    return (
      <li>
        <Link className={itemClassName} to={slug}>
          {shortTitle || title}
        </Link>
      </li>
    );
  }

  return (
    <li className={`${isCollapsed ? 'collapsed' : 'expanded'}`}>
      <button type="button" className={`${itemClassName} collapse-button`} onClick={() => setIsCollapsed(!isCollapsed)}>
        <span className="block">{shortTitle || title}</span>
      </button>

      <div className={`${isCollapsed ? 'sr-only' : 'not-sr-only'}`}>{children}</div>
    </li>
  );
};

export const SidebarMenu = ({ items, slug }: SidebarMenuProps) => {
  if (!items) return null;

  return (
    <ul>
      {sortByPosition(items)?.map((item: SideBarItem) => {
        const hasActiveChildren = item?.treeChildren?.some(({ slug: itemslug }: { slug: string }) =>
          slug.includes(itemslug),
        );

        // @ts-ignore
        const items: SideBarItems = item.treeChildren;

        return (
          <SideBarItem
            hasActiveChildren={hasActiveChildren}
            pageSlug={slug}
            {...item}
            key={`${item.title}-${item.position}`}
          >
            <SidebarMenu items={items} slug={slug} />
          </SideBarItem>
        );
      })}
    </ul>
  );
};

export const Sidebar = ({ sidebar, slug }: SideBarProps): JSX.Element | null => {
  const menu = sidebar?.edges[0]?.node.treeChildren;
  const scrollRestoration = useScrollRestoration('#sidebar') as ClassAttributes<HTMLElement>;
  if (!menu || menu.length < 1) return null;

  return (
    <aside id="sidebar" className="sidebar" data-testid="sidebar" {...scrollRestoration}>
      <nav>
        {sortByPosition(menu)?.map(
          ({ shortTitle, title, showInNavigation, treeChildren, id }: SideBarMenu) =>
            showInNavigation && (
              <React.Fragment key={id}>
                <strong className="block mb-1 mt-6 font-semibold">{shortTitle || title}</strong>
                <SidebarMenu items={treeChildren} slug={slug} />
              </React.Fragment>
            ),
        )}
      </nav>
    </aside>
  );
};
