import { DeepNonNullable } from 'ts-essentials';
import {
  FileFragment as DatoCmsFileFragment,
  SingleLineTextFragment as DatoCmsSingleLineTextFragment,
  TextLinkFragment as DatoCmsTextLinkFragment,
} from '../../../../graphql-types';

export type FileFragment = DatoCmsFileFragment & DeepNonNullable<Pick<DatoCmsFileFragment, 'url'>>;
export type SingleLineTextFragment = DeepNonNullable<DatoCmsSingleLineTextFragment>;
export type TextLinkFragment = DatoCmsTextLinkFragment &
  DeepNonNullable<Pick<DatoCmsTextLinkFragment, 'id' | 'url' | 'text'>>;

export const isSingleLineText = isOfType<SingleLineTextFragment>('DatoCmsSingleLineText');
export const isTextLink = isOfType<TextLinkFragment>('DatoCmsTextLink');

export type GqlBase = {
  __typename: string;
};

function isOfType<T extends GqlBase>(typename: string) {
  return function (item: GqlBase): item is T {
    return item.__typename === typename;
  };
}
