import React, { FormHTMLAttributes, PropsWithChildren } from 'react';
import { HoneypotFragment, Honeypot } from '../Honeypot';

type FormProps = PropsWithChildren<{ honeypot?: Array<HoneypotFragment> } & FormHTMLAttributes<HTMLFormElement>>;

export const Form = ({ children, honeypot, ...formAttrs }: FormProps) => (
  <form {...formAttrs}>
    {children}
    {honeypot && <Honeypot data={honeypot} />}
  </form>
);
