import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { globalHistory } from '@reach/router';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { Link, AlertBarModal } from '@mattr/shared-components';
import { Container } from './container';
import { HeaderSearchBox } from '../search/header-search-box';
import { searchClient, algoliaIndexName } from '../search/search-client';
import { SearchIcon } from '../search/search-icon';
import { SearchResultsBox } from '../search/search-results-box';
import { hasFocus } from '../search/utils';
import { LearnNavQuery } from '../../../../../graphql-types';
import { MobileNavMenuProps, NavLink, NavLinkId } from '../../types/fragments.types';

const isBrowser = typeof window !== 'undefined';

const getHandleOpenNav = (setShow: React.Dispatch<React.SetStateAction<boolean>>) => () => {
  if (isBrowser && window.innerWidth > 1024) setShow(false);
};

const MobileNavMenu = ({ navLinks, redirects, alertBarModal }: MobileNavMenuProps) => (
  <div className="fixed header-learn inset-0 z-50 bg-black-900 mt-12">
    <nav className="px-6 flex pt-10 flex-col h-3/4 justify-between">
      <div className="text-white text-2xl">
        <ul>
          {navLinks.map(({ text, url }) => (
            <li className="border-b border-gray-200 font-semibold py-2" key={`top-navlink-${url}`}>
              <Link to={url} redirects={redirects}>
                {text}
              </Link>
            </li>
          ))}
          <li className="border-b border-gray-200 py-2 font-semibold">
            <Link to="/search">
              <div className="flex justify-between">
                <span>Search</span>
                <span>
                  <SearchIcon stroke="#FFFFFF" />
                </span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex relative">
        <Link className="mr-6" to="https://mattr.global" target="_blank">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
              stroke="#EAD9E3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M9 22V12H15V22" stroke="#EAD9E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
        <Link to="https://github.com/mattrglobal/mattr-website" target="_blank">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path
                d="M9 19.0001C7.33333 19.5001 6.22222 19.3889 5.40741 19.0001C3.77778 18.2223 3.33333 16.3334 2 16.0001M16 22.0001V18.1301C16.0375 17.6532 15.9731 17.1739 15.811 16.7239C15.6489 16.2738 15.3929 15.8635 15.06 15.5201C18.2 15.1701 21.5 13.9801 21.5 8.52006C21.4997 7.12389 20.9627 5.78126 20 4.77006C20.4559 3.54857 20.4236 2.19841 19.91 1.00006C19.91 1.00006 18.73 0.65006 16 2.48006C13.708 1.85888 11.292 1.85888 9 2.48006C6.27 0.65006 5.09 1.00006 5.09 1.00006C4.57638 2.19841 4.54414 3.54857 5 4.77006C4.03013 5.78876 3.49252 7.14352 3.5 8.55006C3.5 13.9701 6.8 15.1601 9.94 15.5501C9.611 15.89 9.35726 16.2955 9.19531 16.74C9.03335 17.1845 8.96681 17.6581 9 18.1301V22.0001"
                stroke="#EAD9E3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
    </nav>
    <div className="relative ">
      <AlertBarModal {...alertBarModal} renderIcon />
    </div>
  </div>
);

export const HeaderButton = ({ show, onClick }: { show: boolean; onClick: () => void }) => (
  <button
    className="lg:hidden relative w-8 h-5 ml-auto"
    onClick={() => onClick()}
    type="button"
    aria-label={`${!show ? 'Show' : 'Hide'} mobile navigation`}
  >
    <div
      className={`absolute w-8 h-0.5  ${show ? 'bottom-0 my-auto bg-white' : 'bg-black'}`}
      style={{
        transform: show ? 'rotate(45deg)' : 'rotate(0deg)',
        top: show ? 0 : '25%',
      }}
    />

    <div
      className={`absolute w-8 h-0.5 ${show ? 'top-0 my-auto bg-white' : 'bg-black'}`}
      style={{
        transform: show ? 'rotate(-45deg)' : 'rotate(0deg)',
        bottom: show ? 0 : '25%',
      }}
    />
  </button>
);

type HeaderProps = {
  redirects?: string[];
};

export const Header = ({ redirects }: HeaderProps): JSX.Element => {
  // @TODO share wrapping functionality for scroll
  const [show, setShow] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const searchContainerEl = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoint();
  const headerHeight = breakpoints.md ? 64 : 168;
  const threshold = isBrowser && window.pageYOffset <= headerHeight;

  useEffect(() => {
    if (!isBrowser) return;

    const handleOpenNav = getHandleOpenNav(setShow);

    window.addEventListener('resize', handleOpenNav);

    return () => window.removeEventListener('resize', handleOpenNav);
  }, [show]);

  const { nav, alertBarModal } = useStaticQuery<LearnNavQuery>(graphql`
    query learnNav {
      nav: datoCmsLearnHeader {
        navLinks {
          id
          text
          url
        }
        lightLogo {
          url
        }
        darkLogo {
          url
        }
        rightItems {
          text
          url
        }
      }
      alertBarModal: datoCmsLearnAlertBarModal {
        text
        url
        contentBlock
        alertType
      }
    }
  `);

  useEffect(() => {
    function onScroll() {
      const currentScrollPos = (isBrowser && window.pageYOffset) || 0;

      const isHeaderVisible =
        (currentScrollPos > headerHeight && prevScrollPos > currentScrollPos) || currentScrollPos < headerHeight;

      setVisible(isHeaderVisible);

      setPrevScrollPos(currentScrollPos);
      setHasScrolled(currentScrollPos > headerHeight);
      if (!isHeaderVisible) {
        setSearchOpen(false);
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [prevScrollPos, setPrevScrollPos, hasScrolled, setHasScrolled, headerHeight]);

  const navLinks: NavLinkId[] = nav && nav.navLinks !== null ? (nav.navLinks as NavLinkId[]) : [];
  const rightItems: NavLink[] = nav && nav.rightItems !== null ? (nav.rightItems as NavLink[]) : [];
  const darkLogo = nav?.darkLogo?.url || undefined;
  const lightLogo = nav?.lightLogo?.url || undefined;

  return (
    <>
      {show && <MobileNavMenu navLinks={navLinks} redirects={redirects} alertBarModal={alertBarModal} />}

      <header
        tabIndex={-1}
        className={cx(
          'header header-learn  flex justify-center align-center items-center z-50 w-full',
          {
            threshold: hasScrolled && threshold,
            visible: visible && hasScrolled,
            init: visible && !hasScrolled,
            'fixed mobile-nav-open': show,
          },
          show ? 'bg-black' : 'bg-gray-00',
        )}
        id="learn-header"
      >
        <div className="relative flex justify-center align-center items-center max-w-8xl mx-auto">
          <Container wrapClassName="header-inner-wrapper" className="header-inner-container">
            <div className="flex items-center justify-between h-16 lg:h-auto">
              <Link to="https://mattr.global" attrs={{ target: '_blank' }} className="w-28 md:w-30 lg:w-48">
                <img className={cx('logo block h-8', { hidden: show })} src={lightLogo} alt="Mattr. " />
                <img className={cx('logo block h-8', { hidden: !show })} src={darkLogo} alt="Mattr. " />
              </Link>
              <span className={cx('header-learn-divider-light flex items-center', { hidden: show })}></span>
              <span className={cx('header-learn-divider-dark flex items-center', { hidden: !show })}></span>
              <Link to="/" className="flex items-center">
                <span
                  className={cx('header-learn flex items-center', {
                    'learn-mobile-nav-open': show,
                    'text-white': show,
                  })}
                >
                  Learn
                </span>
              </Link>

              <nav className="header-nav hidden md:my-10 lg:flex items-center justify-between ml-12 w-full">
                <ul className="flex ">
                  {navLinks.map(({ text, url, id }) => {
                    const isActiveLink =
                      globalHistory.location.pathname === url || globalHistory.location.pathname.includes(url);

                    return (
                      <li
                        className={`${isActiveLink ? 'header-link--active' : 'header-link'} relative header-link`}
                        key={id}
                      >
                        <Link className="px-3 lg:px-2 xl:px-3 py-2 " to={url} title={text} redirects={redirects}>
                          {text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>

              <HeaderButton show={show} onClick={() => setShow(!show)} />
            </div>
          </Container>
          {searchOpen ? (
            <div className="utils items-center ml-auto">
              <div
                className="ais-SearchBox-Header-Container"
                ref={searchContainerEl}
                tabIndex={-1}
                onBlur={(e) => {
                  let keepSearchOpen = false;
                  const target = e.relatedTarget;
                  if (target instanceof Element) {
                    keepSearchOpen = hasFocus(searchContainerEl.current, target);
                  }
                  setSearchOpen(keepSearchOpen);
                }}
              >
                <InstantSearch searchClient={searchClient} indexName={algoliaIndexName}>
                  <Configure hitsPerPage={5} />
                  <HeaderSearchBox />
                  <SearchResultsBox />
                </InstantSearch>
              </div>
            </div>
          ) : (
            <ul className="utils items-center ml-auto">
              {rightItems.map(({ text, url }) => (
                <li className="header-link relative" key={`right-navlink-${url}`}>
                  <Link to={url} className="px-3 lg:px-2 lg:px-3 py-2">
                    {text}
                  </Link>
                </li>
              ))}
              <li className="relative px-3 header-link cursor-pointer">
                <a aria-label="search" title="search" onClick={() => setSearchOpen(true)}>
                  <SearchIcon />
                </a>
              </li>
            </ul>
          )}
        </div>
      </header>
    </>
  );
};
