import React from 'react';
import ReactMarkdown from 'react-markdown';

import { ButtonBar } from './button-bar';

export const MarkdownBody = ({ buttonBar, content, superscript }) => (
  <div className="markdown-body">
    {superscript && <sup className="uppercase font-semibold tracking-wider text-sm md:text-xs">{superscript}</sup>}

    <ReactMarkdown source={content} />
    <ButtonBar buttons={buttonBar?.buttons} />
  </div>
);
