import React from 'react';
import { graphql } from 'gatsby';
import { StructuredText, renderRule } from 'react-datocms';
import { List, Link, Duo, ImageWithText } from '@mattr/shared-components';
import cx from 'classnames';

// TODO(DEBT-012) remove learn deps
import { LearnList } from '../../../../../apps/learn/src/components/cms/learn-list';
import { HtmlModule } from '../../../../../apps/learn/src/components/cms/html-module';
import { ListOfTerms } from '../../../../../apps/learn/src/components/cms/list-of-terms';
import { CodeSnippet } from './code-snippet';
import { LicensesMobile } from '../../../../../apps/learn/src/components/cms/licenses-mobile';

let redirects = [];

// TODO(DEBT-006): Replace process.env with props
if (process.env.GATSBY_WEB_REDIRECTS) {
  redirects = process.env.GATSBY_WEB_REDIRECTS.split(',');
}

export const StructuredTextWrapper = ({ structuredText, className }) => (
  <div className={cx('structured-text', className)}>
    <StructuredText
      data={structuredText}
      renderInlineRecord={({ record, key }) => {
        switch (record.__typename) {
          case 'DatoCmsList':
            return <List key={key} content={record} />;
          case 'DatoCmsLink':
            return (
              <Link to={record.href} key={key} {...record} redirects={redirects}>
                {record.title}
              </Link>
            );
          case 'DatoCmsLearnList':
            return <LearnList key={key} content={record} />;
          case 'DatoCmsHtmlModule':
            return <HtmlModule key={key} content={record} />;
          case 'DatoCmsImage':
            return <img src={record.asset.url} key={key} alt={record.asset.url} />;
          case 'DatoCmsDuoChild':
            return <Duo key={key} content={record} />;
          case 'DatoCmsImageWithTextChild':
            return <ImageWithText key={key} content={record} />;
          case 'DatoCmsTerm':
            return <ListOfTerms key={key} content={record} />;
          case 'DatoCmsLicencesMobile':
            return <LicensesMobile key={key} />;
          default:
            return null;
        }
      }}
      renderLinkToRecord={({ record }) => {
        switch (record.__typename) {
          default:
            return null;
        }
      }}
      // eslint-disable-next-line
      renderBlock={() => {}}
      customRules={[
        renderRule(
          ({ type, code }) => type === 'code' && code && code.length > 20, // @TODO: temporary magic number for inline snippets
          ({ node, key }) => <CodeSnippet language={node.language} code={node.code} key={key} />,
        ),
        renderRule(
          ({ type, level }) => type === 'heading' && level,
          ({ node, key }) => {
            return React.createElement(
              `h${node.level}`,
              {
                key,
                id: node.children[0].value
                  .toLowerCase()
                  .replace(/[^A-Z0-9]+/gi, '-')
                  .replace(/^-+|-+$/g, ''),
              },
              node.children[0].value,
            );
          },
        ),
        renderRule(
          ({ type }) => type === 'link',
          ({ node, key }) => {
            const attrs = (node.meta || []).reduce((prev, item) => {
              prev[item.id] = item.value;

              return prev;
            }, {});

            return (
              <Link attrs={attrs} to={node.url} key={key} redirects={redirects}>
                {node.children[0].value}
              </Link>
            );
          },
        ),
      ]}
    />
  </div>
);

export const structuredTextFragment = graphql`
  fragment StructuredText on DatoCmsStructuredText {
    model {
      apiKey
    }
    structuredText {
      value
      links {
        __typename
        ... on DatoCmsList {
          id: originalId
          itemsToShow
          linkToPage
          showListAsGrid
          listItems {
            id
            originalId
            title
            linkToVideo
            learnMoreLink
            imageIcon {
              url
            }
            contentBody
            iconName
          }
        }
        ... on DatoCmsLearnList {
          id: originalId
          itemsToShow
          linkToPage
          showListAsGrid
          listItems {
            originalId
            id
            title
            linkToVideo
            learnMoreLink
            imageIcon {
              url
            }
            metadata {
              id
              timeToRead
              contentTypes {
                id
                contentType
                icon {
                  url
                }
              }
            }
            contentBody
            iconName
            backgroundColor
            author
            isGradient
            isArticle
            url
            openInNewWindow
          }
        }
        ... on DatoCmsLink {
          id: originalId
          title
          href
          openInNewWindow
        }
        ... on DatoCmsHtmlModule {
          id: originalId
          content
        }
        ... on DatoCmsImage {
          id: originalId
          asset {
            url
            alt
          }
        }
        ... on DatoCmsDuoChild {
          id: originalId
          heading
          leftColumn {
            ... on DatoCmsList {
              model {
                apiKey
              }
              id
              showListAsGrid
              listItems {
                id
                title
                imageIcon {
                  url
                }
                contentBody
                learnMoreLink
              }
            }
            ... on DatoCmsContentSectionChild {
              model {
                apiKey
              }
              id
              content
              image {
                url
              }
              buttonBar {
                buttons {
                  id
                  title
                  href
                  openInNewWindow
                  buttonStyle
                }
              }
            }
            ... on DatoCmsTickList {
              model {
                apiKey
              }
              id
              title
              tickList
            }
            ... on DatoCmsAddressModule {
              title
              address
              addressLink {
                title
                href
                openInNewWindow
              }
              infoEmail {
                title
                href
              }
              model {
                apiKey
              }
            }
            ... on DatoCmsTicklistText {
              id
              model {
                apiKey
              }
              text
              ticklist {
                title
                tickList
              }
            }
          }
          rightColumn {
            ... on DatoCmsList {
              model {
                apiKey
              }
              id
              showListAsGrid
              listItems {
                id
                title
                imageIcon {
                  url
                }
                url
                contentBody
                learnMoreLink
              }
            }
            ... on DatoCmsContentSectionChild {
              model {
                apiKey
              }
              id
              content
              image {
                url
              }
              buttonBar {
                buttons {
                  id
                  title
                  href
                  buttonStyle
                  openInNewWindow
                }
              }
            }
            ... on DatoCmsTickList {
              model {
                apiKey
              }
              id
              title
              tickList
            }
            ... on DatoCmsForm {
              model {
                apiKey
              }
              formName
              successMessage
              submitEndpoint
              hiddenFields {
                name
                value
              }
              honeypot {
                ...Honeypot
              }
            }
            ... on DatoCmsStep {
              model {
                apiKey
              }
              slug
              linkToPage {
                title
                href
                openInNewWindow
              }
              steps {
                title
                contentBody
                metadata {
                  timeToRead
                  contentTypes {
                    contentType
                    icon {
                      url
                    }
                  }
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageWithTextChild {
          id: originalId
          buttons {
            buttons {
              id
              title
              href
              openInNewWindow
              buttonStyle
            }
          }
          superscript
          text
          superscript
          backgroundColour
          image {
            url
            height
            customData
          }
          mobileImage {
            url
            height
            customData
          }

          model {
            apiKey
          }
        }
        ... on DatoCmsTerm {
          id: originalId
          title
          links {
            id
            title
            href
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLicencesMobile {
          id: originalId
          model {
            apiKey
          }
        }
      }
    }
  }
`;
