import React from 'react';

import { ContactForm } from '../form/contact-form';
import { GetStartedForm } from '../form/get-started';
import { RecruitmentForm } from '../form/recruitment-form';
import { FORM_NAME } from '../utils';

export const Form = ({
  content: { formName, referenceFieldValue, successMessage, submitEndpoint, hiddenFields, honeypot },
}) => (
  <div className="w-full lg:w-1/2 ml-auto mt-12 lg:mt-0">
    {formName === 'contact' && (
      <ContactForm
        referenceFieldValue={referenceFieldValue}
        successMessage={successMessage}
        submitEndpoint={submitEndpoint}
        formName={formName}
        hiddenFields={hiddenFields}
        honeypot={honeypot}
      />
    )}
    {formName === 'get-started' && (
      <GetStartedForm
        referenceFieldValue={referenceFieldValue}
        successMessage={successMessage}
        submitEndpoint={submitEndpoint}
        formName={formName}
        hiddenFields={hiddenFields}
        honeypot={honeypot}
      />
    )}
    {formName === FORM_NAME.RECRUITMENT && (
      <RecruitmentForm
        referenceFieldValue={referenceFieldValue}
        successMessage={successMessage}
        submitEndpoint={submitEndpoint}
        formName={formName}
        hiddenFields={hiddenFields}
        honeypot={honeypot}
      />
    )}
  </div>
);
