import React from 'react';
import cx from 'classnames';

export const Container = ({ children, className = '', wrapClassName = '', style = {} }) => (
  <div className={cx(wrapClassName, 'px-6 lg:px-20')}>
    <div className={cx(className, 'max-w-5xl', 'mx-auto')} style={style}>
      {children}
    </div>
  </div>
);
