import React from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';

import { Icon } from '../icon';

export const TickList = ({ content, className = 'mt-10 lg:mt-0 lg:w-1/2' }) => {
  if (!content) return null;

  const containerClassName = cx('tick-list shadow-lg bg-white', className);

  const { title, tickList } = content;

  return (
    <div className={containerClassName}>
      <div className="bg-blue-500 uppercase px-8 py-4 text-white tracking-widest flex justify-between font-semibold">
        {title}
        <Icon className="svg svg-medium" name="box" />
      </div>
      <div className="px-8 py-4">
        <ReactMarkdown source={tickList} />
      </div>
    </div>
  );
};
