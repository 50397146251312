import React from 'react';
import cx from 'classnames';

import { Link } from '../link';

/**
 * @deprecated Please use the Button from `@mattrglobal/components` instead.
 */
export const Button = ({
  className = '',
  title,
  href,
  openInNewWindow,
  onClick,
  children,
  type = 'button',
  disabled,
  buttonStyle,
}) => {
  const buttonColorClassNames = {
    'light-primary': 'bg-blue-500 hover:bg-blue-1000 text-white',
    'light-secondary': 'bg-blue-100 border border-blue-1000 hover:bg-blue-1000 text-blue-1000 hover:text-white',
    'dark-primary-blue-bg': 'bg-white text-blue-1000 border-2 border-white hover:bg-opacity-0 hover:text-white',
    'dark-secondary-black-bg-blue-text':
      'text-blue-1000 hover:bg-opacity-0 bg-magenta-100 border-2 border-magenta-100 hover:border-white hover:text-white',
    'dark-secondary-black-bg-black-text':
      'text-black hover:bg-opacity-0 bg-magenta-100 border-2 border-magenta-100 hover:border-white hover:text-white',
    'dark-secondary-blue-bg':
      'bg-blue-100 text-blue-1000 border-2 border-blue-100 hover:border-white hover:bg-opacity-0 hover:text-white',
    'dark-secondary-black-bg-black-text-reverse':
      'text-white bg-opacity-0 hover:bg-magenta-100 border-2 hover:border-magenta-100 border-white hover:text-black',
  };

  return href ? (
    <Link
      className={cx(className, 'btn transition-bg text-sm hover:underline', buttonColorClassNames[buttonStyle])}
      to={href}
      openInNewWindow={openInNewWindow}
    >
      {title || children}
    </Link>
  ) : (
    <button
      className={cx(className, 'btn transition-bg text-sm hover:underline', buttonColorClassNames[buttonStyle], {
        'cursor-not-allowed bg-gray-300 hover:bg-gray-300': disabled,
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
