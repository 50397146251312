import { Snippet } from '@mattrglobal/components';
import webConfig from '../../../config/webConfig';
import { DevSnippets } from './dev';
import { ProdSnippets } from './prod';
import { StagingSnippets } from './staging';
import { QaSnippets } from './qa';

const ENV = webConfig.SNIPPETS_ENV;
const environmentalSnippets: Record<typeof ENV, Snippet[]> = {
  qa: QaSnippets,
  dev: DevSnippets,
  prod: ProdSnippets,
  staging: StagingSnippets,
};

export const Snippets = environmentalSnippets[ENV] || [];
