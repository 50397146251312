import { Snippet, SnippetPosition } from '@mattrglobal/components';
import matomoAnalyticsTagManager from './matomo-analytics-tag-manager';
import matomoTagManager from './matomo-tag-manager';
import pardotTracking from './pardot-tracking';

const textJavascript = 'text/javascript';
export const ProdSnippets: Snippet[] = [
  {
    id: 'matomo-analytics-tag-manager',
    script: matomoAnalyticsTagManager,
    position: SnippetPosition.HEAD,
    type: textJavascript,
  },
  {
    id: 'matamo-tag-manager',
    script: matomoTagManager,
    position: SnippetPosition.HEAD,
    type: textJavascript,
  },
  {
    id: 'pardot-tracking',
    script: pardotTracking,
    position: SnippetPosition.BODY,
    type: textJavascript,
  },
];
