import React from 'react';
import { graphql } from 'gatsby';
import cx from 'classnames';

import { Container } from '../../layout/container';
import { ImageWithText } from './image-with-text';
import { ContentSection } from '../shared/content-section';
import { List } from '../shared/list';
import { TickList } from '../shared/tick-list';
import { Form } from '../shared/form';
import { Address } from '../shared/address';
import { GithubCards } from '../shared/github-card';
import { TickListText } from '../shared/ticklist-text';
import { Steps } from '../shared/steps';

const CONTENT_ID_COMPONENT_MAPPING = {
  image_with_text_child: ImageWithText,
  content_section_child: ContentSection,
  list: List,
  tick_list: TickList,
  form: Form,
  address_module: Address,
  repository_card_group: GithubCards,
  ticklist_text: TickListText,
  step: Steps,
};

const renderLeftColumn = (content) => {
  const leftColumnContent = content?.leftColumn;
  const LeftColumnComponent = CONTENT_ID_COMPONENT_MAPPING[leftColumnContent?.model?.apiKey] || null;

  if (!LeftColumnComponent) {
    return null;
  }

  // TODO(DEBT-006): Replace process.env with props
  if (process.env.GATSBY_SHOW_MODULE_NAMES === 'true') {
    return (
      <div style={{ borderWidth: '2px' }}>
        <div style={{ color: 'purple' }}>
          <span>{leftColumnContent?.model?.apiKey}</span>
        </div>
        <LeftColumnComponent className="content-section lg:flex-1 lg:mr-20" content={leftColumnContent} />
      </div>
    );
  }

  return <LeftColumnComponent className="content-section lg:flex-1 lg:mr-20" content={leftColumnContent} />;
};

const renderRightColumn = (content) => {
  const rightColumnContent = content?.rightColumn;
  const RightColumnComponent = CONTENT_ID_COMPONENT_MAPPING[rightColumnContent?.model?.apiKey] || null;

  if (!RightColumnComponent) {
    return null;
  }

  // TODO(DEBT-006): Replace process.env with props
  if (process.env.GATSBY_SHOW_MODULE_NAMES === 'true') {
    return (
      <div style={{ borderWidth: '2px', borderColor: 'purple' }}>
        <div style={{ color: 'purple' }}>
          <span>{rightColumnContent?.model?.apiKey}</span>
        </div>
        <RightColumnComponent className="content-section lg:flex-2 lg:mr-20" content={rightColumnContent} />
      </div>
    );
  }

  return <RightColumnComponent className="content-section lg:flex-2 lg:mr-20" content={rightColumnContent} />;
};

export const Duo = ({ content, anchorLink }) => {
  const duoClassName = cx(
    `cms-module duo relative ${
      // eslint-disable-next-line no-underscore-dangle
      content?.rightColumn?.__typename === 'DatoCmsList' ? 'list' : ''
    }`,
    {
      hr: !content?.heading,
      'has-heading': content?.heading,
    },
  );

  return (
    <section className={duoClassName} {...(anchorLink ? { id: anchorLink } : {})}>
      <Container className="duo-container">
        {content?.heading && (
          <h3 className="duo-title block mb-12">
            <span>{content?.heading}</span>
          </h3>
        )}

        <div className="flex-col gap-y-8 flex lg:flex-row lg:gap-y-0">
          {renderLeftColumn(content)}
          {renderRightColumn(content)}
        </div>
      </Container>
    </section>
  );
};

export const duoQueryFragment = graphql`
  fragment Duo on DatoCmsDuo {
    anchorLink
    content {
      heading
      leftColumn {
        ... on DatoCmsList {
          model {
            apiKey
          }
          id
          showListAsGrid
          listItems {
            id
            title
            imageIcon {
              url
            }
            contentBody
            learnMoreLink
          }
        }
        ... on DatoCmsContentSectionChild {
          model {
            apiKey
          }
          id
          content
          image {
            url
          }
          buttonBar {
            buttons {
              id
              title
              href
              openInNewWindow
              buttonStyle
            }
          }
        }
        ... on DatoCmsTickList {
          model {
            apiKey
          }
          id
          title
          tickList
        }
        ... on DatoCmsAddressModule {
          title
          address
          addressLink {
            title
            href
            openInNewWindow
          }
          feedback
          infoEmail {
            title
            href
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTicklistText {
          id
          model {
            apiKey
          }
          text
          ticklist {
            title
            tickList
          }
        }
      }
      rightColumn {
        ... on DatoCmsList {
          model {
            apiKey
          }
          id
          button {
            buttons {
              id
              href
              title
              openInNewWindow
              buttonStyle
            }
          }
          showListAsGrid
          listItems {
            id
            title
            imageIcon {
              url
            }
            url
            contentBody
            learnMoreLink
          }
        }
        ... on DatoCmsContentSectionChild {
          model {
            apiKey
          }
          id
          content
          image {
            url
          }
          buttonBar {
            buttons {
              id
              title
              href
              buttonStyle
              openInNewWindow
            }
          }
        }
        ... on DatoCmsTickList {
          model {
            apiKey
          }
          id
          title
          tickList
        }
        ... on DatoCmsForm {
          model {
            apiKey
          }
          formName
          referenceFieldValue
          successMessage
          submitEndpoint
          hiddenFields {
            name
            value
          }
          honeypot {
            ...Honeypot
          }
        }
        ... on DatoCmsStep {
          model {
            apiKey
          }
          slug
          id
          linkToPage {
            title
            href
            openInNewWindow
          }
          steps {
            title
            contentBody
            metadata {
              id
              timeToRead
              contentTypes {
                id
                contentType
                icon {
                  url
                }
              }
            }
          }
        }
      }
    }
    model {
      apiKey
    }
  }
`;
