import { ButtonBar, Link } from '@mattr/shared-components';
import cx from 'classnames';
import React from 'react';

import ReactMarkdown from 'react-markdown';
import { DatoCmsContentType, DatoCmsMetadataInfo } from '../../../../../graphql-types';

export type ListItemProps = {
  imageIcon: { url: string };
  title: string;
  contentBody: string;
  metadata: DatoCmsMetadataInfo[];
  author: string;
  backgroundColor: string;
  showListAsGrid: boolean;
  isGradient: boolean;
  isArticle: boolean;
  url: string;
  learnMoreLink: string;
  linkToVideo: string;
  itemClassName: string;
  showContent: boolean;
  openInNewWindow: boolean;
  id: string;
};

export type LearnListProps = {
  content?: {
    listItems: ListItemProps[];
    itemClassName: string;
    showListAsGrid: boolean;
    linkToPage: string;
    itemsToShow: number;
    button: Record<string, unknown>;
  };
  showContent?: boolean;
};

const ListItem = ({
  imageIcon,
  title,
  contentBody,
  metadata,
  author,
  backgroundColor,
  showListAsGrid,
  isGradient,
  isArticle,
  url,
  learnMoreLink,
  linkToVideo,
  itemClassName,
  showContent,
  openInNewWindow,
}: ListItemProps) => {
  const backgroundColorMap: { [index: string]: string } = {
    magenta: '#EAD9E3',
    red: '#FADDDC',
    green: '#E1F7E6',
    blue: '#DEDCFF',
  };

  return (
    <li className={cx(itemClassName, 'max-w-lg list-item w-full lg:w-1/3')}>
      <Link {...(url && { to: url })} openInNewWindow={openInNewWindow} className="block pb-6 lg:pr-6 list-container">
        <div
          className={cx({
            'shadow-lg hover:shadow-2xl': metadata?.length > 0 && !isArticle,
            'flex flex-row items-start': showListAsGrid,
          })}
        >
          {imageIcon && (
            <div
              className={cx('relative flex items-center', {
                'mr-4': showListAsGrid,
              })}
              style={
                backgroundColor
                  ? {
                      backgroundColor: backgroundColorMap[backgroundColor],
                      minHeight: '200px',
                    }
                  : {}
              }
            >
              <img
                src={imageIcon.url}
                className={cx({
                  'mx-auto': !isArticle && !linkToVideo,
                  'w-full': linkToVideo,
                  icon: !metadata,
                })}
                aria-hidden="true"
                alt=""
              />
            </div>
          )}
          <div
            className={cx('relative', {
              'border-t-2 border-gray-100': isGradient,
              'text-center': metadata?.length === 0 && !showListAsGrid,
              'm-2 p-2': !showListAsGrid,
              'min-height': metadata,
            })}
          >
            <h4
              className={cx('font-semibold mb-4', {
                'text-blue-500': showListAsGrid && !imageIcon,
                'underline hover:no-underline': showListAsGrid && !imageIcon && url,
              })}
            >
              {title}
            </h4>
            {contentBody && (showContent || showListAsGrid) && (
              <div className="markdown-body">
                <ReactMarkdown source={contentBody} />
                {learnMoreLink && (
                  <a className="underline text-blue-500" href={learnMoreLink} target="_blank" rel="noreferrer noopener">
                    Learn more
                  </a>
                )}
              </div>
            )}
            {author && <span className="text-xs my-4 block">{author}</span>}

            {metadata &&
              metadata?.map(({ contentTypes, timeToRead, id }) => (
                <div
                  className="text-xs w-full flex flex-wrap pr-4 mt-8 pb-4 justify-between items-end absolute bottom-0"
                  key={`learn-metadata-record-${id}`}
                >
                  <ul>
                    {contentTypes &&
                      (contentTypes as DatoCmsContentType[])?.map(({ contentType, icon }) => (
                        <li className="capitalize flex mt-2" key={`${contentType}-${icon?.url}`}>
                          {icon && <img src={icon.url || undefined} className="mr-2" alt="" />}
                          {contentType}
                        </li>
                      ))}
                  </ul>
                  {timeToRead && (
                    <div className="flex items-center">
                      <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                          stroke="#333132"
                        />
                        <path
                          d="M6 5.99996L7.99979 4.84612M6 5.99996V2.15381V5.99996Z"
                          stroke="#333132"
                          strokeLinecap="round"
                        />
                      </svg>
                      <span className="ml-2 block">{timeToRead}</span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export const LearnList = ({ content, showContent = true }: LearnListProps): JSX.Element | null => {
  if (!content) return null;

  const { listItems, itemClassName, showListAsGrid, linkToPage, itemsToShow, button } = content;

  return (
    <div
      className={cx('list learn-list w-full mt-0 pr-6 lg:pr-0', {
        'mt-8': linkToPage,
      })}
    >
      <>
        <ul className="flex flex-wrap flex-col md:flex-row -mx-6">
          {listItems &&
            listItems.map((item, i) => {
              if (itemsToShow && i > itemsToShow - 1) return null;

              return (
                <ListItem
                  {...item}
                  itemClassName={itemClassName}
                  key={item.id}
                  showListAsGrid={showListAsGrid}
                  showContent={showContent}
                />
              );
            })}
        </ul>
        {button?.buttons && (
          <div className="text-center mx-auto mt-12">
            {' '}
            <ButtonBar {...button} />
          </div>
        )}
      </>
    </div>
  );
};
