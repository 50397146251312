import React from 'react';
import ReactMarkdown from 'react-markdown';

import { TickList } from './tick-list';

export const TickListText = ({ content }) => (
  <div className="w-full md:w-5/12">
    {content.text && (
      <div className="mb-12">
        <ReactMarkdown source={content.text} />
      </div>
    )}

    {content && <TickList className="w-full" content={content.ticklist} />}
  </div>
);
