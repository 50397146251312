import React from 'react';
import cx from 'classnames';

import { Icon } from '../icon';
import { Link } from '../link';
import { Container } from '../../layout/container';

const languageButtonColor = {
  standard: 'bg-blue-100',
  typescript: 'bg-teal-300',
  cSharp: 'bg-green-300',
};

const Card = ({ title, text, language, metadata, logo, url }) => {
  return (
    <li className="w-full lg:w-1/3 p-4 text-xs block">
      <a
        className="flex flex-col bg-white github-card-shadow hover:shadow-2xl p-3 gap-2 md:min-h-[200px] min-h-[100px]"
        href={url}
      >
        <div className="font-semibold">{title}</div>
        <p className="line-clamp-2">{text}</p>
        <div className="grow" />
        {language.code !== 'standard' && language.name && (
          <div className="w-fit px-2 py-0.5 rounded-full bg-base-grey-100">
            <span style={{ fontSize: '12px' }}>{language.name}</span>
          </div>
        )}
        <div className="flex items-center justify-between w-full">
          {metadata.map((item, i) => (
            <>
              <div className="flex justify-between" key={`github-card-metadata-item-${item.id}`}>
                <div className="items-center flex mr-3">
                  <Icon name="pump" />
                  <span className="ml-1">{item.repoName}</span>
                </div>
                {item.license && (
                  <div className="items-center flex">
                    <Icon name="scale" />
                    <span className="ml-1">{item.license}</span>
                  </div>
                )}
              </div>
              <div>
                <Icon name={logo === 'github' ? 'github' : 'globe'} />
              </div>
            </>
          ))}
        </div>
      </a>
    </li>
  );
};

export const GithubCards = ({ content }) => {
  const githubCardList = content.repoCard;

  if (!githubCardList) {
    return null;
  }

  return (
    <section>
      <Container>
        <ul className="flex flex-wrap mt-10 -m-4">
          {githubCardList.map((item, i) => (
            <Card {...item} key={`github-item-card-${item.id}`} />
          ))}
        </ul>
        <div className="text-center mt-12">
          <Link className="link" to={content.url} attrs={{ target: '_blank' }}>
            See more ↗
          </Link>
        </div>
      </Container>
    </section>
  );
};
