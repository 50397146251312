import * as fs from 'fs';

export interface AppConfig {
  DATO_CMS_ENV: string;
  DATO_CMS_PREVIEW_MODE: boolean;
  DEFAULT_API_VERSION: string;
  GATSBY_ACTIVE_ENV: 'development' | 'production';
  GATSBY_ALGOLIA_APP_ID: string;
  GATSBY_ALGOLIA_INDEX_NAME: string;
  GATSBY_SHOW_MODULE_NAMES: boolean;
  REDOC_API_URL: string;
  SITE_URL: string;
  SNIPPETS_ENV: 'qa' | 'prod' | 'staging' | 'dev';
}

/**
 * Because config.json is unreachable for webConfig in browser,
 * store the app config in process.env,
 * then webpack can compile those configs into bundle.
 */
if (!process.env.GATSBY_APP_CONFIG) {
  process.env.GATSBY_APP_CONFIG = fs.readFileSync(`./config/config.${process.env.APP_ENV}.json`, {
    encoding: 'utf8',
    flag: 'r',
  });
}

const appConfig: AppConfig = JSON.parse(process.env.GATSBY_APP_CONFIG);

export default appConfig;
