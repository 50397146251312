import algoliasearch from 'algoliasearch';

import config from '../../../config/webConfig';

const algoliaClient = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_PUBLIC_SEARCH_KEY);
export const algoliaIndexName = config.ALGOLIA_INDEX_NAME || '';

export const searchClient = {
  search(requests: Array<{ params: { query: string }; indexName: string }>): Readonly<Promise<SearchQueryResults>> {
    const request = requests[0];
    // Prevent search on empty value
    const shouldSearch = request && request.params.query.length > 2;

    if (shouldSearch) {
      return algoliaClient.search(requests);
    }

    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
};
