import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import { Link } from '@mattr/shared-components';
import MagentaArrowRight from '../../images/magenta-arrow-right.svg';
import BlueArrowRight from '../../images/blue-arrow-right.svg';
import { MobileNavIndexQueryQuery } from '../../../../../graphql-types';

type NavItemProps = {
  text: string;
  url: string;
  listItemStyle: string;
  i: number;
  length: number;
  blurb: string;
  id: string;
};

const NavItem = ({ text, url, listItemStyle, i, length, blurb }: NavItemProps): JSX.Element => {
  const listItemStyleMap: { [index: string]: string } = {
    internal: 'text-white bg-black',
    external: 'text-black bg-white',
  };

  return (
    <li className={listItemStyleMap[listItemStyle]}>
      <div
        className={cx('border-b-2 py-4 mx-6', listItemStyle, {
          'border-gray-200': listItemStyle === 'internal',
          'border-black': listItemStyle === 'external',
          'border-b-0 border-none': i >= length - 1,
        })}
      >
        <Link className="block  " to={url} openInNewWindow={listItemStyle === 'external'}>
          <h2 className="font-semibold text-2xl mb-4">{text}</h2>
          <p className={`blurb ${listItemStyle} text-sm`}>
            {blurb}{' '}
            {listItemStyle === 'internal' ? (
              <MagentaArrowRight className="inline ml-2" />
            ) : (
              <BlueArrowRight className="inline ml-2" />
            )}
          </p>
        </Link>
      </div>
    </li>
  );
};

export const MobileIndexNav = (): JSX.Element => {
  const { nav } = useStaticQuery<MobileNavIndexQueryQuery>(graphql`
    query MobileNavIndexQuery {
      nav: datoCmsLearnHeader {
        navLinks {
          id
          text
          url
          blurb
        }
        lightLogo {
          url
        }
        darkLogo {
          url
        }
        rightItems {
          id
          text
          url
          blurb
        }
      }
    }
  `);

  const navLinks: NavItemProps[] = (nav && (nav.navLinks as NavItemProps[])) || [];
  const rightItems: NavItemProps[] = (nav && (nav.rightItems as NavItemProps[])) || [];

  return (
    <section>
      <div className="mobile-nav-showcase markdown-body w-full px-6 pt-16 pb-8">
        {/*TODO: was ReactMarkdown source={showcaseHero.text} which we need to find out where that is showing on datocms and replace with following text*/}
        <h3>Guides, tutorials and docs to help you understand and build a decentralized identity solution</h3>
      </div>
      <ul className="mobile-nav-items">
        {navLinks.map((item: NavItemProps, i: number) => (
          <NavItem {...item} i={i} length={navLinks.length} listItemStyle="internal" key={item.id} />
        ))}

        {rightItems.map((item: NavItemProps, i: number) => (
          <NavItem {...item} i={i} length={rightItems.length} listItemStyle="external" key={item.id} />
        ))}
      </ul>
    </section>
  );
};
