import { SiteNames } from './constants';

export const getFriendlyCategoryName = (topCategory: string): string => {
  const sites = Object.keys(SiteNames);

  for (const site of sites) {
    const values = SiteNames[site].terms;
    for (const value of values) {
      if (topCategory.toLowerCase().indexOf(value) > -1) {
        return site;
      }
    }
  }

  return topCategory;
};

export const categoryToPillname = (topCategory: string): string => {
  const sites = Object.keys(SiteNames);

  for (const site of sites) {
    const values = SiteNames[site].terms;
    for (const value of values) {
      if (topCategory.toLowerCase().indexOf(value) > -1) {
        return SiteNames[site].pillName;
      }
    }
  }

  return '';
};

export const getSubCategoryName = (subCategory = ''): string => (subCategory.split('>')[1] || '').trim();

export const hasFocus = (containerElem: Node | null, focusElem: Node | null): boolean => {
  if (!containerElem) {
    return false;
  }
  let node = focusElem;
  if (!node) {
    return false;
  }
  while (node && node !== document.body) {
    if (node === containerElem) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};
