import cx from 'classnames';
import React from 'react';

export const Container = ({
  children,
  className = '',
  wrapClassName = '',
}: {
  children: React.ReactNode;
  className?: string;
  wrapClassName?: string;
}): JSX.Element => (
  <div className={cx(wrapClassName, 'container px-6 lg:px-20')}>
    <div className={cx(className, 'container-inner max-w-5xl mx-auto')}>{children}</div>
  </div>
);
