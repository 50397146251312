import { Link } from '@mattr/shared-components';
import React from 'react';

import LeftCaretIcon from '../../images/caret-left.svg';
import RightCaretIcon from '../../images/caret-right.svg';

import { sortByPositionPrev } from '../utils';
import { PrevNextButtonsProps } from '../../types/fragments.types';

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const PrevNextButtons = ({ prevNextButtons }: { prevNextButtons: PrevNextButtonsProps }): JSX.Element | null => {
  if (prevNextButtons === null) {
    return null;
  }

  const childrenToShow = prevNextButtons.treeParent?.treeChildren
    ?.filter((child) => child !== null && child.showInNavigation === true && child.content?.length)
    .filter(notEmpty);

  if (!childrenToShow) {
    return null;
  }

  const sortedChildren = sortByPositionPrev(childrenToShow);
  const currentIndex = sortedChildren.findIndex((child: SideBarItem) => child.slug === prevNextButtons.slug);
  const isLastPage = currentIndex === sortedChildren.length - 1;
  const isFirstPage = currentIndex === 0;
  const previous = !isFirstPage && sortedChildren[currentIndex - 1];
  const next = !isLastPage && sortedChildren[currentIndex + 1];

  return (
    <ul className="prev-next-buttons mt-12 md:flex">
      <li className="prev-next-button md:w-1/2 md:pt-12">
        {previous && (
          <Link className="block py-6 md:pb-12 md:pl-12 md:pt-8" to={previous.slug}>
            <sup className="uppercase block text-sm">
              <LeftCaretIcon /> Prev
            </sup>
            <span className="font-semibold">{previous.shortTitle || previous.title}</span>
          </Link>
        )}
      </li>

      <li className="prev-next-button md:w-1/2 md:pt-12">
        {next && (
          <Link className="block py-6 md:pb-12 md:pl-12 md:pt-8" to={next.slug}>
            <sup className="uppercase block text-sm">
              Next <RightCaretIcon />
            </sup>
            <span className="font-semibold">{next.shortTitle || next.title}</span>
          </Link>
        )}
      </li>
    </ul>
  );
};
