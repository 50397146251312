export const hitsPerPage = 10;

/**
 * Friendly names for search result categories. Search results map to a specific site.
 * Any new site added in Algolia needs to be added here to show up in the category filter.
 */
export const SiteNames: { [key: string]: SearchSite } = {
  // The terms are meant to cover the dev and prod environments. At the moment the results are categorized based
  // on their url. The terms will be tested in order, first positive result wins.
  'MATTR Learn': { terms: ['learn'], subcategories: true, pillName: 'LEARN' },
  'MATTR Global': { terms: ['marketing', 'global'], subcategories: false, pillName: 'MATTR GLOBAL' },
  Articles: { terms: ['medium'], subcategories: false, pillName: 'ARTICLES' },
};
