import React from 'react';
import { graphql } from 'gatsby';

import { BACKGROUND_COLOR_TO_CLASS } from '../../tools/utilities/constants';

export const Separator = ({ bottomColour = 'black', topColour = 'black' }) => (
  <div className="separator" aria-hidden>
    <span className={`separator ${BACKGROUND_COLOR_TO_CLASS[topColour]}`} />
    <span className={`separator ${BACKGROUND_COLOR_TO_CLASS[bottomColour]}`} />
  </div>
);

export const SeparatorQueryFragment = graphql`
  fragment Separator on DatoCmsSeparator {
    bottomColour
    topColour
    model {
      apiKey
    }
  }
`;
