import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, AlertBarModal } from '@mattr/shared-components';
import { CustomSnippets, SnippetPosition } from '@mattrglobal/components';
import SVGs from '../../assets/svg-icons/all.svg';

import { Header } from './header';
import { Head } from './head';
import { Footer } from './footer';
import { PrevNextButtons } from './prev-next-buttons';
import { Breadcrumbs } from './breadcrumbs';
import { Sidebar } from './sidebar';
import { SidebarMobile } from './sidebar-mobile';
import { MobileIndexNav } from './mobile-index-nav';
import config from '../../../config/webConfig';
import {
  AlertModalQuery,
  LearnPageQuery,
  BreadcrumbsProps,
  PrevNextButtonsProps,
  MetadataSchemaProps,
  PagePropsSlug,
  SeoMetaTagsProps,
} from '../../types/fragments.types';
import { Snippets } from '../../assets/snippets';

let redirects: Array<string> = [];

if (config.WEB_REDIRECTS) {
  redirects = config.WEB_REDIRECTS.split(',');
}

type LayoutProps = {
  children: React.ReactNode;
  slug?: PagePropsSlug;
  breadcrumbs?: BreadcrumbsProps;
  sidebar?: LearnPageQuery['sidebar'];
  prevNextButtons?: PrevNextButtonsProps;
  seoMetaTags?: SeoMetaTagsProps;
  metadataSchema?: MetadataSchemaProps;
};

export const Layout = ({
  children,
  slug,
  breadcrumbs,
  sidebar,
  prevNextButtons,
  seoMetaTags,
  metadataSchema,
}: LayoutProps): JSX.Element => {
  const { alertBarModal } = useStaticQuery<AlertModalQuery>(graphql`
    query alertModal {
      alertBarModal: datoCmsLearnAlertBarModal {
        text
        url
        contentBlock
        alertType
        openInNewWindow
      }
    }
  `);

  const renderMain = () => {
    if (slug === '/') {
      return (
        <>
          <div className="lg:hidden">
            <MobileIndexNav />
          </div>
          <main className="learn-site home hidden lg:block">{children}</main>
        </>
      );
    }

    return (
      <main className="lg:mt-10 learn-site relative">
        {sidebar && slug && <SidebarMobile sidebar={sidebar} slug={slug} />}
        <Container>
          <div className="flex">
            {sidebar && slug && <Sidebar sidebar={sidebar} slug={slug} />}

            <div className="content max-w-full">
              <div className="md:px-8">
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                {children}
              </div>

              {prevNextButtons && <PrevNextButtons prevNextButtons={prevNextButtons} />}
            </div>
          </div>
        </Container>
      </main>
    );
  };

  return (
    <>
      <SVGs aria-hidden="true" />
      <CustomSnippets snippetPosition={SnippetPosition.HEAD} snippets={Snippets} />
      <Head metadataSchema={metadataSchema} seoMetaTags={seoMetaTags} />
      <div className="hidden lg:block relative" aria-hidden="true">
        <AlertBarModal {...alertBarModal} renderIcon />
      </div>
      <Header redirects={redirects} />
      {renderMain()}
      <Footer redirects={redirects} />
      <CustomSnippets snippetPosition={SnippetPosition.BODY} snippets={Snippets} />
    </>
  );
};
