import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import { sortByPosition } from '../utils';
import { SidebarMenu } from './sidebar';

const isBrowser = typeof window !== 'undefined';

export const SidebarMobile = ({ sidebar, slug }: SideBarProps): JSX.Element | null => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const headerHeight = 64;
  const headerThreshold = isBrowser && window.pageYOffset <= headerHeight;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef?.current && show && !wrapperRef?.current.contains(event.target as Node)) {
        setShow(!show);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    show ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible');

    function onScroll() {
      const currentScrollPos = (isBrowser && window.pageYOffset) || 0;

      setVisible(
        (currentScrollPos > headerHeight && prevScrollPos > currentScrollPos) || currentScrollPos < headerHeight,
      );

      setPrevScrollPos(currentScrollPos);
      setHasScrolled(currentScrollPos > headerHeight);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [prevScrollPos, setPrevScrollPos, hasScrolled, setHasScrolled, show]);

  if (!sidebar.edges[0]?.node) return null;

  const { treeChildren: menu } = sidebar.edges[0].node;

  return (
    <div
      className={cx('sidebar-mobile-container flex md:hidden pb-20  z-40', {
        'sidebar-open fixed w-4/5 inset-y-0 h-full bg-white': show,
        'header-threshold': headerThreshold,
        'header-visible': visible && hasScrolled,
        init: visible && !hasScrolled,
        'sidebar-shut': !show,
      })}
      ref={wrapperRef}
    >
      <button
        className={`h-16 w-full fixed top-0 left-0 font-semibold text-left pl-6 ${
          show ? 'bg-magenta-100 text-black' : 'bg-black-900 text-white'
        }`}
        onClick={() => setShow(!show)}
      >
        {show ? 'Hide contents' : 'Show contents'}
      </button>
      {show && (
        <aside id="sidebar-mobile" className={cx('sidebar-mobile mt-16 pl-6 w-full')}>
          <nav className="overflow-scroll h-full pb-24 pr-6">
            {sortByPosition(menu)?.map(
              ({ shortTitle, title, showInNavigation, treeChildren }: SideBarMenu) =>
                showInNavigation && (
                  <>
                    <strong className="block mb-1 mt-6 font-semibold">{shortTitle || title}</strong>
                    <SidebarMenu items={treeChildren} slug={slug} />
                  </>
                ),
            )}
          </nav>
        </aside>
      )}
    </div>
  );
};
