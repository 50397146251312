import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactMarkdown from 'react-markdown';

import { Input, Checkbox, TextArea } from './inputs';
import { Select } from './select';
import { countries } from './countries';
import { Link } from '../link';
import { Captcha } from './captcha';
import { Form } from './Form';

import { handleFormSubmit, toSelectOptions, deserializeHiddenFields } from './utils';
import { getHoneypotFieldsWithInitialValue } from './Honeypot';

export const GetStartedForm = ({
  successMessage,
  referenceFieldValue,
  submitEndpoint,
  formName,
  hiddenFields,
  honeypot,
}) => {
  const [token, setToken] = useState(null);
  const [isSubmissionOk, setIsSubmissionOk] = useState(false);

  const roleTypes = [
    'CxO - Technical',
    'CxO - Business/Non-Technical',
    'Technical Developer',
    'Operations/Project Lead',
    'Consultant',
    'Sales/Marketing',
    'Other',
  ];
  const hearAboutUsSource = [
    'Search Engine',
    'Auth0 Marketplace',
    'Referral/Word of Mouth',
    'Linkedin',
    'YouTube',
    'Other',
  ];

  const getStartedFormValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('This is a required field.'),
    firstName: Yup.string().required('This is a required field.'),
    lastName: Yup.string().required('This is a required field.'),
    organisation: Yup.string().required('This is a required field'),
    roleType: Yup.string().required('This is a required field'),
    termsOfUse: Yup.boolean().oneOf([true], 'Must accept Terms and Conditions'),
  });

  return (
    <Formik
      initialValues={{
        ...{
          firstName: '',
          lastName: '',
          organisation: '',
          organisationWebsite: '',
          email: '',
          country: '',
          roleType: '',
          hearAboutUs: '',
          tellUsMore: '',
          termsOfUse: false,
          mailingList: false,
          reference: referenceFieldValue,
        },
        ...getHoneypotFieldsWithInitialValue(honeypot),
      }}
      validationSchema={getStartedFormValidation}
      onSubmit={(values, actions) => {
        if (values) {
          handleFormSubmit({
            url: submitEndpoint,
            values,
            formName,
            hiddenFieldsValues: deserializeHiddenFields(hiddenFields),
          }).then(() => {
            actions.setSubmitting(false);
            setIsSubmissionOk(true);
          });
        }
      }}
    >
      {({ handleSubmit, handleChange, errors, values, touched, isValid, isSubmitting }) => (
        <Form name="mattr-get-started" onSubmit={handleSubmit} honeypot={honeypot}>
          <input type="hidden" name="form-name" value="mattr-get-started" />
          <Input
            label="Name"
            value={values.firstName}
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            errorMessage={touched.firstName && errors.firstName}
            required
          />
          <Input
            name="lastName"
            value={values.lastName}
            placeholder="Last Name"
            onChange={handleChange}
            errorMessage={touched.lastName && errors.lastName}
            required
          />
          <Input
            name="email"
            value={values.email}
            label="Work email"
            onChange={handleChange}
            errorMessage={touched.email && errors.email}
            required
          />
          <Input
            name="organisation"
            value={values.organisation}
            label="Organisation"
            onChange={handleChange}
            errorMessage={touched.organisation && errors.organisation}
            required
            message="If you are signing up as an individual, enter 'n/a'."
          />
          <Input
            name="organisationWebsite"
            value={values.organisationWebsite}
            label="Organisation website"
            onChange={handleChange}
            errorMessage={touched.organisationWebsite && errors.organisationWebsite}
            message="If you are signing up as an individual, enter 'n/a'."
          />
          <Select
            value={values.roleType}
            label="Role"
            name="roleType"
            options={toSelectOptions(roleTypes)}
            errorMessage={touched.roleType && errors.roleType}
            onChange={handleChange}
            required
            defaultOption="- Select a type of role -"
          />
          <Select
            value={values.country}
            label="Country"
            name="country"
            options={countries}
            errorMessage={touched.country && errors.country}
            onChange={handleChange}
            defaultOption="- Select a country -"
          />
          <TextArea
            label="Anything else you want to tell us?"
            value={values.tellUsMore}
            name="tellUsMore"
            onChange={handleChange}
            rows={2}
            errorMessage={touched.tellUsMore && errors.tellUsMore}
          />
          <Select
            value={values.hearAboutUs}
            label="How did you hear about us?"
            name="hearAboutUs"
            options={toSelectOptions(hearAboutUsSource)}
            errorMessage={touched.hearAboutUs && errors.hearAboutUs}
            onChange={handleChange}
            defaultOption="- Select a type of hear about us -"
          />
          <Input type="hidden" name="reference" value={values.reference} />
          <Checkbox
            onChange={handleChange}
            value={values.termsOfUse}
            errorMessage={touched.termsOfUse && errors.termsOfUse}
            name="termsOfUse"
            className="block"
            id="termsOfUseID"
          >
            <label name="termsOfUse" className="text-sm" htmlFor="termsOfUseID">
              I agree to the{' '}
              <Link
                className="link"
                to="https://learn.mattr.global/terms/customer-agreement"
                attrs={{ target: '_blank' }}
              >
                MATTR Customer Agreement
              </Link>{' '}
              <span className="text-magenta-500">*</span>
            </label>
          </Checkbox>
          <Checkbox onChange={handleChange} name="mailingList" className="text-sm" id="mailingListID">
            <label name="mailingList" htmlFor="mailingListID">
              I would like to sign up to the MATTR mailing list
            </label>
          </Checkbox>

          <div className="block w-full mt-8">
            {!isSubmissionOk && (
              <Captcha token={token} setToken={setToken} isSubmitting={isSubmitting} isValid={isValid} />
            )}
            {isSubmissionOk && (
              <div className="mt-8">
                <ReactMarkdown source={successMessage} />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
