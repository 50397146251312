import React from 'react';
import { Icon, Link } from '@mattrglobal/components';

import * as styles from './SubmenuItem.module.css';
import { SubmenuItemFragment } from '../../../../types';

export type SubmenuItemProps = {
  data: SubmenuItemFragment;
};

export const SubmenuItem = ({
  data: { title, url, openInNewWindow, icon, description, tip, tipBackgroundColor, tipColor },
}: SubmenuItemProps) => {
  return (
    <div>
      <Link url={url} external={openInNewWindow} className={styles.linkContainer}>
        {icon?.url && (
          <div className={styles.icon}>
            <img className={styles.img} src={icon.url} data-testid="submenu-item-icon" />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <span className={styles.text}>{title}</span>
            {openInNewWindow && <Icon.External className={styles.external} />}
            {tip && (
              <span
                style={{ color: tipColor?.hex ?? undefined, backgroundColor: tipBackgroundColor?.hex ?? undefined }}
                className={styles.tip}
              >
                {tip}
              </span>
            )}
          </div>
          {description && <div className={styles.desc}>{description}</div>}
        </div>
      </Link>
    </div>
  );
};
