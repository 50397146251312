import React from 'react';
import { graphql } from 'gatsby';

import { CallToAction } from './call-to-action';

export const FeaturedImage = ({ image, cta, anchorLink }) => (
  <section
    className="cms-module featured-image relative flex items-center flex-col bg-gray-10"
    {...(anchorLink ? { id: anchorLink } : {})}
  >
    <CallToAction content={cta} />
    <img className="px-6 pt:6 lg:p-0 lg:w-1/2" src={image?.url} alt={image?.alt} />
  </section>
);

export const featuredImageQueryFragment = graphql`
  fragment FeaturedImage on DatoCmsFeaturedImage {
    anchorLink
    cta {
      superscript
      title
    }
    image {
      url
      alt
    }
    model {
      apiKey
    }
  }
`;
