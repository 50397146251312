import React from 'react';
import { graphql } from 'gatsby';

import { CallToAction } from './call-to-action';
import { ButtonBar } from './button-bar';
import { List } from './list';
import { Container } from '../../layout/container';

export const FeaturedList = ({ callToAction, image, buttons = null, subtext, leadingText, list, anchorLink }) => {
  return (
    <section className="cms-module featured-list bg-gray-100 relative" {...(anchorLink ? { id: anchorLink } : {})}>
      <CallToAction content={callToAction} />

      <Container>
        <div className="lg:flex lg:flex-col lg:items-center text-left lg:text-center ">
          <p className="block lg:w-1/2 mb-10 ">{leadingText}</p>

          <div className="text-center">
            <List content={list} showContent />
          </div>

          <p className="block lg:w-1/2">{subtext}</p>

          {buttons?.buttons?.length && <ButtonBar buttons={buttons?.buttons} />}

          {image && <img className="px-6 mt-12 lg:p-0 lg:w-full" src={image?.url} alt={image?.alt} />}
        </div>
      </Container>
    </section>
  );
};

export const FeaturedListQueryFragment = graphql`
  fragment FeaturedList on DatoCmsFeaturedList {
    anchorLink
    subtext
    leadingText
    callToAction {
      title
      superscript
    }
    image {
      url
      alt
    }
    list {
      showListAsGrid
      listItems {
        id
        title
        imageIcon {
          url
        }
        contentBody
      }
    }
    buttons {
      buttons {
        id
        title
        href
        openInNewWindow
        buttonStyle
      }
    }
    model {
      apiKey
    }
  }
`;
