export class AccordionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AccordionError';
  }
}

export class AccordionItemError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AccordionItemError';
  }
}
