// NOTE: This is a generated file, nothing you do here matters
// The source of the all.svg file is located at './src/assets/svg-icons/all.svg'
// The script that generates this file is located at tools/svg-generator.js
// To rebuild this file run 'yarn run generate-svgs'
import React from 'react';

export default {
  'alert-circle': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#alert-circle" />
    </svg>
  ),
  box: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 28">
      <use xlinkHref="#box" />
    </svg>
  ),
  circles: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159 128">
      <use xlinkHref="#circles" />
    </svg>
  ),
  clock: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <use xlinkHref="#clock" />
    </svg>
  ),
  'code-bg': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 53">
      <use xlinkHref="#code-bg" />
    </svg>
  ),
  code: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 53">
      <use xlinkHref="#code" />
    </svg>
  ),
  cube: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 64">
      <use xlinkHref="#cube" />
    </svg>
  ),
  device: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 69">
      <use xlinkHref="#device" />
    </svg>
  ),
  github: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#github" />
    </svg>
  ),
  globe: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <use xlinkHref="#globe" />
    </svg>
  ),
  governance: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 98">
      <use xlinkHref="#governance" />
    </svg>
  ),
  hex: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 82">
      <use xlinkHref="#hex" />
    </svg>
  ),
  key: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 61">
      <use xlinkHref="#key" />
    </svg>
  ),
  linkedin: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23">
      <use xlinkHref="#linkedin" />
    </svg>
  ),
  list: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 47">
      <use xlinkHref="#list" />
    </svg>
  ),
  'lock-speech': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134 145">
      <use xlinkHref="#lock-speech" />
    </svg>
  ),
  'logo-dif-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 47">
      <use xlinkHref="#logo-dif-blue" />
    </svg>
  ),
  'logo-dif': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 47">
      <use xlinkHref="#logo-dif" />
    </svg>
  ),
  'logo-hyperledger-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243 47">
      <use xlinkHref="#logo-hyperledger-blue" />
    </svg>
  ),
  'logo-hyperledger': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243 47">
      <use xlinkHref="#logo-hyperledger" />
    </svg>
  ),
  'logo-ietf-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 47">
      <use xlinkHref="#logo-ietf-blue" />
    </svg>
  ),
  'logo-ietf': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 47">
      <use xlinkHref="#logo-ietf" />
    </svg>
  ),
  'logo-openid-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153 47">
      <use xlinkHref="#logo-openid-blue" />
    </svg>
  ),
  'logo-openid': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153 47">
      <use xlinkHref="#logo-openid" />
    </svg>
  ),
  'logo-w3c-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 47">
      <use xlinkHref="#logo-w3c-blue" />
    </svg>
  ),
  'logo-w3c': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 47">
      <use xlinkHref="#logo-w3c" />
    </svg>
  ),
  phone: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 69">
      <use xlinkHref="#phone" />
    </svg>
  ),
  pins: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 66">
      <use xlinkHref="#pins" />
    </svg>
  ),
  'plus-plus': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 48">
      <use xlinkHref="#plus-plus" />
    </svg>
  ),
  pump: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
      <use xlinkHref="#pump" />
    </svg>
  ),
  ribbon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 103">
      <use xlinkHref="#ribbon" />
    </svg>
  ),
  scale: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
      <use xlinkHref="#scale" />
    </svg>
  ),
  speech: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 54">
      <use xlinkHref="#speech" />
    </svg>
  ),
  tick: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 50">
      <use xlinkHref="#tick" />
    </svg>
  ),
  twitter: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
      <use xlinkHref="#twitter" />
    </svg>
  ),
  union: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
      <use xlinkHref="#union" />
    </svg>
  ),
  window: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 69">
      <use xlinkHref="#window" />
    </svg>
  ),
  x: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13">
      <use xlinkHref="#x" />
    </svg>
  ),
};
