export const sortByPosition = <T extends { position: number }[]>(items: T): T | undefined =>
  items?.sort((a, b) => a.position - b.position);

export const sortByPositionPrev = <
  T extends {
    slug: string | null;
    shortTitle: string | null;
    title: string | null;
    position: number | null;
  }[]
>(
  items: T,
): T =>
  items.sort((a, b) => {
    if (a.position !== null && b.position !== null) {
      return a.position - b.position;
    }

    return 0;
  });
