import React from 'react';
import { graphql } from 'gatsby';

import { Container } from '../../layout/container';
import { MarkdownBody } from '../shared/markdown-body';
import { Button } from '../shared/button';
import { Separator } from './separator';
import { stripMarkdownHeadings, formattedDate } from '../utils';

export const JobListing = ({
  allPageModules,
  applyNowLink,
  closingDate,
  id,
  jobDescriptionLink,
  jobTitle,
  jobType,
  location,
  text,
}) => {
  const jobListings = allPageModules?.filter((module) => module?.model?.apiKey === 'job_listing');
  const isFirstJobListing = jobListings[0].id === id;

  return (
    <div className="job-listing">
      <Container>
        {isFirstJobListing && (
          <>
            <div className="flex mb-24 flex-col justify-center lg:items-center">
              <ul>
                {jobListings.map((item) => {
                  const formattedTitle = stripMarkdownHeadings(item.jobTitle);

                  return (
                    <li className="text-center" key={item.id}>
                      <a className="font-semibold hover:underline" href={`#${formattedTitle}`}>
                        {formattedTitle}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            <Separator />
          </>
        )}

        <div id={stripMarkdownHeadings(jobTitle)} className="flex justify-center">
          <div className="max-w-screen-md cms-module relative	">
            <MarkdownBody content={jobTitle} />

            <dl className="mb-8">
              {jobType && [
                <dt key="jobType">
                  <strong className="font-semibold">Job type: </strong>
                </dt>,
                <dd key="jobTypeValue">{jobType}</dd>,
              ]}

              {location && [
                <dt key="location">
                  <strong className="font-semibold">Location: </strong>
                </dt>,
                <dd key="locationValue">{location}</dd>,
              ]}

              {closingDate && [
                <dt key="closingDate">
                  <strong className="font-semibold">Closing date: </strong>
                </dt>,
                <dd key="closingDateValue">{formattedDate(closingDate)}</dd>,
              ]}
            </dl>

            <MarkdownBody content={text} />

            {jobDescriptionLink && (
              <>
                <a
                  className="text-blue-500 underline hover:no-underline"
                  target="_blank"
                  href={jobDescriptionLink.url}
                  rel="noreferrer noopener"
                >
                  {jobDescriptionLink.title || 'Read job description'} ↗
                </a>

                <br />
              </>
            )}

            <Button className="mt-6" {...applyNowLink} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export const jobListingQueryFragment = graphql`
  fragment JobListing on DatoCmsJobListing {
    text
    jobType
    id
    jobTitle
    location
    closingDate
    jobDescriptionLink {
      url
      title
    }
    applyNowLink {
      title
      href
      openInNewWindow
      buttonStyle
    }
    model {
      apiKey
    }
  }
`;
