import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactMarkdown from 'react-markdown';

import { Input, Checkbox, TextArea } from './inputs';
import { Select } from './select';
import { Captcha } from './captcha';
import { Form } from './Form';
import { getHoneypotFieldsWithInitialValue } from './Honeypot';
import { countries } from './countries';
import { handleFormSubmit, toSelectOptions, toPardotContactFormFields, deserializeHiddenFields } from './utils';

export const ContactForm = ({
  successMessage,
  referenceFieldValue,
  submitEndpoint,
  formName,
  hiddenFields,
  honeypot,
}) => {
  const [token, setToken] = useState(null);
  const [isSubmissionOk, setIsSubmissionOk] = useState(false);
  const roleTypes = [
    'CxO – Technical',
    'CxO - Business/Non-Technical',
    'Technical Developer',
    'Operations/Project Lead',
    'Consultant',
    'Sales/Marketing',
    'Other',
  ];
  const solutionsList = [
    'Verifying credentials',
    'Generating credentials',
    'Credential management',
    'AAMVA conference topics',
    'All of the above',
    'Unsure',
  ];

  const contactFormValidationSchema = Yup.object().shape({
    country: Yup.string().required('This is a required field.'),
    email: Yup.string().email('Please enter a valid work email.').required('This is a required field.'),
    firstName: Yup.string().required('This is a required field.'),
    lastName: Yup.string().required('This is a required field.'),
  });

  return (
    <Formik
      initialValues={{
        ...{
          firstName: '',
          lastName: '',
          email: '',
          organisation: '',
          organisationWebsite: '',
          roleType: '',
          country: '',
          phoneNumber: '',
          whichSolution: '',
          tellUsMore: '',
          mailingList: '',
          reference: referenceFieldValue,
        },
        ...getHoneypotFieldsWithInitialValue(honeypot),
      }}
      validationSchema={contactFormValidationSchema}
      onSubmit={async (values, actions) => {
        if (!values) {
          return;
        }

        const urls = submitEndpoint.split(',');

        await Promise.all(
          urls.map((url) =>
            handleFormSubmit({
              url,
              values: toPardotContactFormFields(values),
              formName,
              hiddenFieldsValues: deserializeHiddenFields(hiddenFields),
            }),
          ),
        );

        actions.setSubmitting(false);
        setIsSubmissionOk(true);
      }}
    >
      {({ handleSubmit, handleChange, errors, values, touched, isValid, isSubmitting }) => (
        <Form name="mattr-contact" onSubmit={handleSubmit} honeypot={honeypot}>
          <input type="hidden" name="form-name" value="mattr-contact" />
          <Input
            label="Name"
            value={values.firstName}
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            errorMessage={touched.firstName && errors.firstName}
            required
          />
          <Input
            name="lastName"
            value={values.lastName}
            placeholder="Last Name"
            onChange={handleChange}
            errorMessage={touched.lastName && errors.lastName}
          />
          <Input
            id="email"
            dataTestId="email"
            name="email"
            value={values.email}
            label="Work Email"
            onChange={handleChange}
            errorMessage={touched.email && errors.email}
            required
          />
          <Input
            name="organisation"
            value={values.organisation}
            label="Organisation"
            onChange={handleChange}
            errorMessage={touched.organisation && errors.organisation}
            message="If you are contacting us as an individual, enter 'n/a'."
          />
          <Input
            name="organisationWebsite"
            value={values.organisationWebsite}
            label="Organisation website"
            onChange={handleChange}
            errorMessage={touched.organisationWebsite && errors.organisationWebsite}
            message="If you are signing up as an individual, enter 'n/a'."
          />
          <Select
            value={values.roleType}
            label="Role"
            name="roleType"
            options={toSelectOptions(roleTypes)}
            errorMessage={touched.roleType && errors.roleType}
            onChange={handleChange}
            defaultOption="- Select a type of role -"
          />
          <Select
            value={values.country}
            label="Country"
            name="country"
            dataTestId="country"
            options={countries}
            errorMessage={touched.country && errors.country}
            onChange={handleChange}
            required
            defaultOption="- Select a country -"
          />
          <Input name="phoneNumber" value={values.phoneNumber} label="Phone number" onChange={handleChange} />
          <Select
            value={values.roleType}
            label="Which of the MATTR solutions are you interested in?"
            name="whichSolution"
            options={toSelectOptions(solutionsList)}
            errorMessage={touched.whichSolution && errors.whichSolution}
            onChange={handleChange}
            required
            defaultOption="- Select MATTR solution -"
          />
          <Input type="hidden" name="reference" value={values.referenceFieldValue} />
          <TextArea
            label="Anything else you want to tell us?"
            value={values.tellUsMore}
            name="tellUsMore"
            onChange={handleChange}
            rows={2}
            errorMessage={touched.tellUsMore && errors.tellUsMore}
          />
          <Checkbox onChange={handleChange} name="mailingList" className="text-sm" id="mailingListID">
            <label name="mailingList" htmlFor="mailingListID">
              I would like to sign up to the MATTR mailing list
            </label>
          </Checkbox>
          <div className="block w-full mt-8">
            {!isSubmissionOk && (
              <Captcha token={token} setToken={setToken} isSubmitting={isSubmitting} isValid={isValid} />
            )}
            {isSubmissionOk && (
              <div className="mt-8">
                <ReactMarkdown source={successMessage} />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
