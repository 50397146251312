import { ReactNode, cloneElement, isValidElement } from 'react';
import cx from 'classnames';
import { isEqual } from 'lodash';

import { useHasScrolledUp, useHasScrolledDownOver } from '../utils';
import * as styles from './Floating.module.css';

type ScrollState = {
  hasScrollCrossedTheLine: boolean;
  hasScrolledUp: boolean;
};

type FloatingStyleMapping = [ScrollState, string];

export function applyFloatingStyles(scrollState: ScrollState): string | undefined {
  const floatingStyleCollections: Array<FloatingStyleMapping> = [
    [{ hasScrollCrossedTheLine: true, hasScrolledUp: false }, styles.floating],
    [{ hasScrollCrossedTheLine: true, hasScrolledUp: true }, cx(styles.floating, styles.active)],
  ];

  return floatingStyleCollections.find(([neededScrollState]) => isEqual(neededScrollState, scrollState))?.[1];
}

export type FloatingProps = {
  children: ReactNode;
  triggerPosition?: number;
  inactiveClassName?: string;
};

export const Floating = ({ children, triggerPosition = 0, inactiveClassName }: FloatingProps) => {
  const hasScrolledUp = useHasScrolledUp();
  const hasScrollCrossedTheLine = useHasScrolledDownOver(triggerPosition);

  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(children, {
    className: cx(
      children.props.className,
      styles.basis,
      !hasScrollCrossedTheLine && inactiveClassName,
      applyFloatingStyles({ hasScrollCrossedTheLine, hasScrolledUp }),
    ),
  });
};
