import React from 'react';

import { Link, flatten } from '@mattr/shared-components';
import type { BreadcrumbsProps, BreadcrumbsPropsValues } from '../../types/fragments.types';

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadcrumbsProps }): JSX.Element | null => {
  // We're looking up the tree from the current page path so reverse on format
  const formattedBreadcrumbs = flatten([breadcrumbs], 'treeParent').reverse() as Array<BreadcrumbsProps>;

  if (formattedBreadcrumbs.length <= 1) return null;

  return (
    <ul className="breadcrumbs text-gray-700 text-sm mb-6">
      <li className="inline">
        <span className="mr-1 hover:underline hover:text-blue-500">
          <Link to="/">Learn</Link>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4f4f4f"
          strokeWidth="2"
          className="svg feather feather-chevron-right"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </li>

      {formattedBreadcrumbs.map((breadcrumb, index) => {
        const breadcrumbsValues: BreadcrumbsPropsValues = breadcrumb as BreadcrumbsPropsValues;
        const { content, shortTitle, title, slug } = breadcrumbsValues;
        const isLastCrumb = index === formattedBreadcrumbs.length - 1;
        const hasContent = content && content.length >= 1;
        const breadcrumbTitle = shortTitle || title;

        return (
          <li className="inline" key={breadcrumbTitle}>
            <span className="mx-1">
              {hasContent ? (
                <Link className="hover:underline hover:text-blue-500" to={slug}>
                  {breadcrumbTitle}
                </Link>
              ) : (
                breadcrumbTitle
              )}
            </span>
            {!isLastCrumb && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#4f4f4f"
                strokeWidth="2"
                className="svg feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            )}
          </li>
        );
      })}
    </ul>
  );
};
