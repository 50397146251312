import React from 'react';
import { createConnector, SearchBox } from 'react-instantsearch-dom';
import { navigate } from 'gatsby';

import { SearchIcon } from './search-icon';

const queryConnector = createConnector({
  displayName: 'QueryConnector',
  getProvidedProps(props, searchState) {
    return { query: searchState.query };
  },
});

export const HeaderSearchBox = queryConnector(
  ({ query }): JSX.Element => {
    return (
      <SearchBox
        searchAsYouType={true}
        autoFocus={true}
        submit={<SearchIcon />}
        onSubmit={(e) => {
          e.preventDefault();
          if (query && query.length) {
            navigate(`/search?query=${query}`);
          }
        }}
      />
    );
  },
);
