import React from 'react';
import { graphql } from 'gatsby';

import { BACKGROUND_COLOR_TO_CLASS } from '../../tools/utilities/constants';

import { CallToAction } from '../shared/call-to-action';
import { ButtonBar } from '../shared/button-bar';

export const CallToActionBanner = ({ callToAction, buttons, backgroundColour }) => (
  <div className={`cms-module pt-32 ${BACKGROUND_COLOR_TO_CLASS[backgroundColour]}`}>
    <div className="flex flex-col items-center text-center">
      <CallToAction titleClassName="markdown-body w-full" content={callToAction} />
      <ButtonBar buttons={buttons?.buttons} />
    </div>
  </div>
);

export const callToActionQueryFragment = graphql`
  fragment CallToActionBanner on DatoCmsCallToActionBanner {
    buttons {
      buttons {
        id
        title
        href
        openInNewWindow
        buttonStyle
      }
    }
    backgroundColour
    callToAction {
      title
      superscript
    }
    model {
      apiKey
    }
  }
`;
