export const isBrowser = typeof window !== 'undefined';
export const stripMarkdownHeadings = (string) => string.replace(/#|\*/g, '').trim();

export const formattedDate = (date) =>
  new Date(date).toLocaleDateString('en-NZ', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const flatten = (data, childField) =>
  data.reduce((accum, curr) => {
    accum = [...accum, curr];

    if (curr && curr[childField]) {
      accum = [...accum, ...flatten([curr[childField]], childField)];
    }

    return accum;
  }, []);

export const FORM_NAME = {
  RECRUITMENT: 'recruitment',
};
