import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Icon } from '../icon';
import { Link } from '../link';

export const Address = ({ content }) => (
  <div className="text-left ml-auto">
    <h2 className="font-semibold text-2xl mb-4">{content?.title}</h2>
    <address className="not-italic mb-4">
      <ReactMarkdown source={content?.address} />
    </address>
    <Link
      className="link block mb-4"
      to={content.addressLink.href}
      attrs={content.addressLink.openInNewWindow && { target: 'blank' }}
    >
      {content.addressLink.title}
    </Link>
    {content?.feedback && (
      <div className="block mb-4">
        <ReactMarkdown source={content?.feedback} />
      </div>
    )}
    <Link className="link" to={content.infoEmail.href}>
      {content.infoEmail.title}
    </Link>
    <div className="social-icons mt-4">
      <Link to="https://github.com/MATTRglobal" attrs={{ target: '_blank' }} className="mr-4">
        <Icon name="github" />
      </Link>
      <Link
        className="mr-4"
        to="https://www.linkedin.com/company/mattrglobal/?originalSubdomain=nz"
        attrs={{ target: '_blank' }}
      >
        <Icon name="linkedin" />
      </Link>
      <Link className="mr-4" to="https://twitter.com/mattrglobal?lang=en" attrs={{ target: '_blank' }}>
        <Icon name="twitter" />
      </Link>
    </div>
  </div>
);
