import React, { useEffect, useState } from 'react';

import JSONData from '../../assets/license-report.json';

type JSONDataKey = keyof typeof JSONData;
type JSONDataType = typeof JSONData[JSONDataKey] & { repository?: string };

const LibraryEntry = ({ name, licenseText, version }: JSONDataType): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpenDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <h4 onClick={toggleOpenDetails} className="text-blue-500 font-regular">
        <a style={{ cursor: 'pointer' }}>{name}</a>
      </h4>
      <div style={{ boxSizing: 'border-box', paddingBottom: '10px' }} className="text-black">
        <pre
          className="text-sm"
          style={
            isOpen
              ? {
                  padding: '10px',
                  border: '1px solid #ddd',
                }
              : { display: 'none' }
          }
        >
          Version {version} <br />
          <br />
          {licenseText}
        </pre>
      </div>
    </div>
  );
};

export const LicensesMobile = (): JSX.Element => {
  const [licenseModel, setLicenseModel] = useState<JSONDataType[]>([]);

  useEffect(() => {
    const array: JSONDataType[] = [];
    (Object.keys(JSONData) as JSONDataKey[]).forEach(function (key) {
      array.push(JSONData[key]);
    });
    setLicenseModel(array);
  }, []);

  return (
    <div className="licenses-mobile">
      {licenseModel &&
        licenseModel.map((licenseEntry) => {
          return (
            <LibraryEntry
              key={`${licenseEntry.repository}/${licenseEntry.name}@${licenseEntry.version}`}
              {...licenseEntry}
            />
          );
        })}
    </div>
  );
};
