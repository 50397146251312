import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import { Link } from '@mattr/shared-components';
import { Container } from './container';
import GithubIcon from '../../images/github.svg';
import TwitterIcon from '../../images/twitter.svg';
import LinkedInIcon from '../../images/linkedin.svg';
import MediumIcon from '../../images/medium.svg';
import { LearnFooterQuery } from '../../../../../graphql-types';

type Props = {
  redirects?: string[];
};

type TermsPrivacyLink = {
  title: string;
  href: string;
  id: string;
};

type GenericLink = {
  text: string;
  title: string;
  href: string;
  url: string;
  id: string;
  openInNewWindow: boolean;
};

type GenericWithSubItemLink = {
  text: string;
  url: string;
  title: string;
  id: string;
  openInNewWindow: boolean;
  subpageLink: GenericLink[];
};

const FooterTermsPrivacyLinks = ({ termsPrivacyLinks }: { termsPrivacyLinks: TermsPrivacyLink[] }) => (
  <>
    {termsPrivacyLinks.map(({ title, href, id }, index) => (
      <Link className={cx(' hover:underline', { 'mr-4': index === 0 })} to={href} key={id}>
        {title}
      </Link>
    ))}
  </>
);

export const Footer = ({ redirects }: Props): JSX.Element => {
  const { footer, globalLinks } = useStaticQuery<LearnFooterQuery>(graphql`
    query learnFooter {
      footer: datoCmsLearnFooter {
        logo {
          url
        }
        externalLinks {
          id
          title
          subpageLink {
            id
            title
            href
            openInNewWindow
          }
        }
        learnLinks {
          id
          text
          url
        }
        termsPrivacyLinks {
          id
          title
          href
        }
        legalText
      }
      globalLinks: datoCmsLearnHeader {
        navLinks {
          id
          text
          url
        }
      }
    }
  `);

  const termsPrivacyLinks =
    footer && footer.termsPrivacyLinks !== null ? (footer.termsPrivacyLinks as TermsPrivacyLink[]) : [];
  const learnLinks = footer && footer.learnLinks !== null ? (footer.learnLinks as GenericLink[]) : [];
  const externalLinks =
    footer && footer.externalLinks !== null ? (footer.externalLinks as GenericWithSubItemLink[]) : [];
  const globalLinksNavLinks =
    globalLinks && globalLinks.navLinks !== null ? (globalLinks?.navLinks as GenericLink[]) : [];

  return (
    <>
      <footer className="block footer-learn text-md lg:hidden bg-black pt-12 pb-6">
        <Container>
          <div className="flex flex-col">
            <nav>
              <ul className="text-white text-center mx-auto w-full font-semibold">
                {globalLinksNavLinks.map(({ text, url, id }) => (
                  <li className="mb-2" key={id}>
                    <Link to={`${url}`}>{text}</Link>
                  </li>
                ))}
              </ul>
            </nav>
            <div>
              <a href="https://mattr.global" target="_blank" rel="noreferrer noopener">
                <img src={footer?.logo?.url ?? ''} className="mx-auto border border-white m-8" alt="MATTR Logo" />
              </a>
            </div>
            <div className="w-full py-6 mx-auto">
              <h6 className="text-gray-300 text-center mb-3">Follow us</h6>
              <div className="w-full flex justify-center py-2">
                <Link to="https://github.com/MATTRglobal" openInNewWindow>
                  <GithubIcon className="rounded-full h-5 w-5 " />
                </Link>
                <Link to="https://www.linkedin.com/company/mattrglobal/?originalSubdomain=nz" openInNewWindow>
                  <LinkedInIcon className="rounded-full h-5 w-5 ml-4" />
                </Link>
                <Link
                  to="https://twitter.com/MattrGlobal?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  openInNewWindow
                >
                  <TwitterIcon className="rounded-full h-5 w-5 ml-4" />
                </Link>
                <Link to="https://medium.com/mattr-global" openInNewWindow>
                  <MediumIcon className="rounded-full h-5 w-5 ml-4" />
                </Link>
              </div>
            </div>
            <div className="border-t border-gray-300 text-white py-6">
              <div className="flex justify-between text-xs">
                <span className="text-gray-300"> © MATTR, {new Date().getFullYear()}.</span>
                <FooterTermsPrivacyLinks termsPrivacyLinks={termsPrivacyLinks} />
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <footer className="hidden lg:block footer-learn text-lg bg-gray-00">
        <Container>
          <nav className="hidden lg:flex flex-wrap flex-row justify-around w-full pt-12 font-lighter">
            <div className="w-full lg:w-3/12 relative">
              <ul className="learn-links flex flex-wrap flex-col font-semibold">
                {learnLinks.map(({ text, url, id }) => (
                  <li className="mb-4 hover:underline focus:underline" key={id}>
                    <Link to={url} redirects={redirects}>
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="lg:w-2/12" />

            {externalLinks.map(
              (item, footerLinkIndex) =>
                footerLinkIndex < 2 &&
                item && (
                  <div className="block lg:w-2/12" key={item.id}>
                    <h2
                      className={cx('font-semibold mb-6', {
                        'md:mt-6 lg:mt-0': footerLinkIndex > 2,
                      })}
                    >
                      {item.title}
                    </h2>
                    <ul>
                      {item.subpageLink?.map(({ title, href, openInNewWindow, id: subPageId }) => (
                        <li className="hover:underline focus:underline mb-2" key={subPageId}>
                          <Link to={href} title={title} attrs={openInNewWindow && { target: '_blank' }}>
                            {title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ),
            )}

            <div className="hidden lg:block lg:w-1/12 md:mt-auto lg:mt-0 lg:ml-auto pt-24 pb-12 lg:py-0">
              <a href="https://mattr.global" target="_blank" rel="noreferrer noopener">
                <img src={footer?.logo?.url ?? ''} alt="MATTR Logo" />
              </a>
              <div className="block md:hidden pt-1">© MATTR, {new Date().getFullYear()}.</div>
            </div>
          </nav>

          <div className="hidden md:block border-t border-black-900 mt-12 pb-8 pt-6">
            <div className="flex justify-between">
              © MATTR, {new Date().getFullYear()}.
              <div>
                <FooterTermsPrivacyLinks termsPrivacyLinks={termsPrivacyLinks} />
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};
