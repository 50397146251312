import React from 'react';
import { Highlight } from 'react-instantsearch-dom';

export const Excerpt = ({ hit }: { hit: Hit }): JSX.Element => {
  if (Array.isArray(hit._highlightResult.text)) {
    hit._highlightResult.text = [
      hit._highlightResult.text
        .filter((h) => h.matchLevel == 'full')
        .sort((a, b) => {
          return a.matchedWords.length - b.matchedWords.length;
        })[0] || hit._highlightResult.text[0],
    ];
  }

  return <Highlight hit={hit} attribute="text" tagName="mark" />;
};
