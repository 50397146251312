import React from 'react';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { Container, Button, BACKGROUND_COLOR_TO_CLASS } from '@mattr/shared-components';

import ReactMarkdown from 'react-markdown';
import { ShowcaseHeroFragment } from './ShowcaseHero.types';

export type ShowcaseHeroProps = {
  backgroundColour: keyof typeof BACKGROUND_COLOR_TO_CLASS;
} & ShowcaseHeroFragment;

/**
 * @remarks
 *
 * This component was migrated from the legacy `@mattr/shared-components`,
 * it doesn't follow all rules of cms-component,
 * please not use it as a reference for any new component.
 */
export const ShowcaseHero = ({
  anchorLink,
  contentAreaSpan,
  contentContainerStyle,
  superscript,
  text,
  backgroundImage,
  callToAction,
  backgroundGradient,
  textColour,
  icon,
  backgroundColour,
}: ShowcaseHeroProps) => {
  const wrapperClassName = cx(
    'cms-module showcase-hero bg-center bg-no-repeat bg-cover py-0',
    `text-${textColour ?? 'black'}`,
    {
      'background-image': backgroundImage,
      'separator-bottom': !icon,
      [BACKGROUND_COLOR_TO_CLASS[backgroundColour]]: backgroundColour,
      gradient: backgroundGradient,
    },
  );

  const contentClassName = cx({
    'pt-20': !backgroundImage && icon,
    'py-32': backgroundImage && !icon,
    'py-24': !backgroundImage && !icon,
    'py-20': backgroundImage && icon,
  });

  /**
   * Due to Tailwindcss JIT mode,
   * it needs to explicitly list the possible utility classnames for its compiler to include them,
   * otherwise the dynamic utility classnames doesn't work.
   */
  const widthOptions = [
    '',
    'lg:w-1/12',
    'lg:w-2/12',
    'lg:w-3/12',
    'lg:w-4/12',
    'lg:w-5/12',
    'lg:w-6/12',
    'lg:w-7/12',
    'lg:w-8/12',
    'lg:w-9/12',
    'lg:w-10/12',
    'lg:w-11/12',
    'lg:w-full',
  ];

  const contentAreaClassName = cx(
    'markdown-body w-10/12 mx-auto flex flex-col items-center text-center',
    contentAreaSpan ? widthOptions[contentAreaSpan] : 'lg:w-7/12',
  );

  const containerStyle = JSON.parse(contentContainerStyle) ?? {};

  return (
    <section
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage?.fluid?.src ?? backgroundImage?.url})`,
            }
          : {}
      }
      id={anchorLink ?? undefined}
      className={wrapperClassName}
    >
      <Container className={contentClassName} style={containerStyle}>
        <div className={contentAreaClassName}>
          {icon && icon.url && <img className="mb-4" src={icon.url} />}
          {superscript && <sup className="pb-6 font-semibold uppercase">{superscript}</sup>}

          {text && <ReactMarkdown source={text} />}
        </div>

        {callToAction && (
          <div className="w-full my-6 text-center">
            <Button {...callToAction} />
          </div>
        )}
      </Container>
    </section>
  );
};

export const heroQueryFragment = graphql`
  fragment ShowcaseHero on DatoCmsShowcaseHero {
    anchorLink
    contentAreaSpan
    contentContainerStyle
    id
    superscript
    text
    textColour
    icon {
      url
    }
    backgroundImage {
      url
      fluid(maxWidth: 1440) {
        ...GatsbyDatoCmsFluid
      }
    }
    callToAction {
      title
      href
      openInNewWindow
      buttonStyle
    }
    backgroundColour
    backgroundGradient
    model {
      apiKey
    }
  }
`;
