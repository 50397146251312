import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { Container } from '../../layout/container';

export const CallToAction = ({ anchorLink, content, titleClassName = 'markdown-body lg:w-6/12' }) => {
  if (!content) return null;

  const { title, superscript } = content;

  return (
    <Container>
      <section id={anchorLink} className="cms-module lg:pb-8 lg:text-center lg:flex lg:flex-col lg:items-center">
        {superscript && (
          <sup className="uppercase font-semibold tracking-wider mb-6 block text-sm md:text-xs">{superscript}</sup>
        )}

        {title && <div className={titleClassName}>{title && <ReactMarkdown source={title} />}</div>}
      </section>
    </Container>
  );
};

export const callToActionQueryFragment = graphql`
  fragment CallToAction on DatoCmsCallToAction {
    anchorLink
    content {
      title
      superscript
    }
    model {
      apiKey
    }
  }
`;
