import React, { useState } from 'react';
import { Link } from '../link';
import { Icon } from '../icon';

const logos = [
  { name: 'logo-w3c', url: 'https://www.w3.org/' },
  { name: 'logo-dif', url: 'https://identity.foundation/' },
  { name: 'logo-ietf', url: 'https://www.ietf.org/' },
  { name: 'logo-hyperledger', url: 'https://www.hyperledger.org/' },
  { name: 'logo-openid', url: 'https://openid.net/' },
];

export const LogoGrid = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex justify-between w-full flex-wrap items-center -mx-4">
      {logos.map(({ name, url }) => {
        return (
          <div
            onMouseEnter={() => setIsHovered(name)}
            onMouseLeave={() => setIsHovered(false)}
            className="mb-8 w-1/2 md:w-1/3 px-4 flex-grow"
            key={`logo-${name}`}
          >
            <Link to={url} attrs={{ target: '_blank' }} key={'logo-grid-item-'}>
              {isHovered === name ? (
                <Icon name={`${name}-blue`} className="logo-icon" />
              ) : (
                <Icon name={name} className="logo-icon" />
              )}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
