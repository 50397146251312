import React from 'react';

import { Button } from './button';

export const ButtonBar = ({ buttons }) => {
  if (!buttons) return null;

  return (
    <div>
      {buttons?.map((button) => (
        <Button className="mr-5 my-2" {...button} key={button.id} />
      ))}
    </div>
  );
};
