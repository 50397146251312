import { AlertBarModal } from './components/alert-modal';
import { AnimationHero } from './components/sections/animation-hero';
import { CallToActionBanner } from './components/sections/call-to-action-banner';
import { Duo } from './components/sections/duo';
import { Feature } from './components/sections/feature';
import { ImageWithText } from './components/sections/image-with-text';
import { JobListing } from './components/sections/job-listing';
import { Separator } from './components/sections/separator';
import { ButtonBar } from './components/shared/button-bar';
import { CallToAction } from './components/shared/call-to-action';
import { FeaturedImage } from './components/shared/featured-image';
import { FeaturedList } from './components/shared/featured-list';
import { StructuredTextWrapper } from './components/shared/structured-text';
import { TextContent } from './components/shared/text-content';

export { AnimationHero };
export { ButtonBar };
export { CallToActionBanner };
export { Duo };
export { Feature };
export { ImageWithText };
export { JobListing };
export { Separator };
export { CallToAction };
export { FeaturedImage };
export { FeaturedList };
export { TextContent };
export { StructuredTextWrapper };
export { AlertBarModal };

export { Icon } from './components/icon';
export { toDarkIcon } from './components/icon';
export { toLightIcon } from './components/icon';
export { Link } from './components/link';
export { Button } from './components/shared/button';
export { List } from './components/shared/list';
export * from './components/form/Honeypot';
export { formattedDate } from './components/utils';
export { isBrowser, flatten } from './components/utils';
export { stripMarkdownHeadings } from './components/utils';

export { Container } from './layout/container';
export { BACKGROUND_COLOR_TO_CLASS } from './tools/utilities/constants';

export const TopLevelComponents = {
  animation_hero: AnimationHero,
  call_to_action_banner: CallToActionBanner,
  duo: Duo,
  feature: Feature,
  image_with_text: ImageWithText,
  job_listing: JobListing,
  separator: Separator,
  call_to_action: CallToAction,
  featured_image: FeaturedImage,
  featured_list: FeaturedList,
  text_content: TextContent,
  structured_text: StructuredTextWrapper,
};
