import React from 'react';

export const ResponsiveBackgroundImage = ({ backgroundImage, mobileBackgroundImage }) => {
  if (!backgroundImage || !mobileBackgroundImage) return null;

  return (
    <>
      {backgroundImage && (
        <div
          className="responsive-background-image desktop-responsive-background-image"
          style={{
            backgroundImage: `url(${backgroundImage?.fluid?.src ?? backgroundImage?.url})`,
          }}
        />
      )}

      {mobileBackgroundImage && (
        <div
          className="responsive-background-image mobile-responsive-background-image"
          style={{
            backgroundImage: `url(${mobileBackgroundImage?.fluid?.src ?? mobileBackgroundImage?.url})`,
          }}
        />
      )}
    </>
  );
};
