import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// TODO(DEBT-006): Replace process.env with props
const apiVersion = process.env.GATSBY_DEFAULT_API_VERSION;
const apiPathShort = 'api-ref';
const apiPath = 'api-reference';

// Detect if a link is to the API docs (redocly)
const isApiLink = (href) => {
  if (!href || !href.length || href.length < apiPathShort.length) {
    return false;
  }

  let url = href;
  if (href.startsWith('http')) {
    url = new URL(href).pathname;
  }

  return url.startsWith(`/${apiPathShort}`);
};

// Add the API version to redocly links if needed. This is required for url fragments to work on the redocly site.
// Non API doc links are not modified.
const modifyApiLink = (href) => {
  if (!isApiLink(href)) {
    return href;
  }

  // For now only modify urls that have fragments
  if (href.indexOf('#') === -1) {
    return href;
  }

  let index = href.indexOf(apiPathShort) + apiPathShort.length;
  if (href.indexOf(apiPath) > -1) {
    index = href.indexOf(apiPath) + apiPath.length;
  }

  const params = href.slice(index);
  const apiLink = `/${apiPath}/${apiVersion}${params}`;

  return apiLink;
};

/**
 * @deprecated Please use the Link from `@mattrglobal/components` instead.
 */
export const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  attrs,
  title,
  className,
  openInNewWindow,
  redirects = [],
}) => {
  let formattedAttrs = attrs;
  const isInternalLink = /^\/(?!\/)/.test(to);

  const isRedirect = redirects.some((entry) => to.startsWith(entry)) || isApiLink(to);

  if (isInternalLink && !isRedirect) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        aria-label={title}
        className={className}
      >
        {children}
      </GatsbyLink>
    );
  }

  if (openInNewWindow) formattedAttrs = { ...attrs, target: '_blank' };

  return (
    <a href={modifyApiLink(to)} {...formattedAttrs} aria-label={title} className={className} rel="noreferrer noopener">
      {children}
    </a>
  );
};
