import React, { useState } from 'react';
import { Field } from 'formik';
import cx from 'classnames';
import { Icon } from '../icon';

export const Select = ({
  label,
  name,
  options,
  onChange,
  required,
  defaultOption,
  errorMessage,
  dataTestId,
  value,
}) => {
  const [isIconClicked, setIsIconClicked] = useState(false);

  return (
    <div className="mb-4">
      {label && (
        <label htmlFor={name} className="block text-sm font-semibold font-medium text-gray-700 pb-1">
          {label} {required && <span className="text-magenta-500">*</span>}
        </label>
      )}
      <div className="relative flex items-center">
        <Field
          as="select"
          onChange={onChange}
          name={name}
          className={cx('block w-full sm:text-sm border border-black pl-2 py-2', {
            'border-red-500': errorMessage,
          })}
          data-testid={dataTestId}
        >
          {defaultOption && (
            <option disabled hidden value="">
              {defaultOption}
            </option>
          )}
          {options.map(({ label: optionLabel }) => (
            <option key={optionLabel} value={optionLabel}>
              {optionLabel}
            </option>
          ))}
        </Field>
        {errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-6 flex items-center">
            <div
              className={cx('alert-icon', { clicked: isIconClicked })}
              onClick={() => setIsIconClicked(!isIconClicked)}
            >
              <Icon name="alert-circle" fill="none" />
            </div>
            <span className={cx('alert-message', { clicked: isIconClicked })}>{errorMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};
