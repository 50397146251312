import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactMarkdown from 'react-markdown';

import { Input, TextArea } from './inputs';
import { Select } from './select';
import { Captcha } from './captcha';
import { Form } from './Form';

import { countries } from './countries';
import { handleFormSubmit, toSelectOptions, deserializeHiddenFields } from './utils';
import { FORM_NAME } from '../utils';
import { getHoneypotFieldsWithInitialValue } from './Honeypot';

export const RecruitmentForm = ({
  successMessage,
  referenceFieldValue,
  submitEndpoint,
  formName,
  hiddenFields,
  honeypot,
}) => {
  const [token, setToken] = useState(null);
  const [isSubmissionOk, setIsSubmissionOk] = useState(false);
  const hearAboutUsSource = ['LinkedIn', 'SEEK', 'SEO Search', 'Employee Referral', 'Other'];

  const recruitmentFormValidationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('This is a required field.'),
    firstName: Yup.string().required('This is a required field.'),
    lastName: Yup.string().required('This is a required field.'),
    role: Yup.string().required('This is a required field.'),
    country: Yup.string().required('This is a required field.'),
  });

  return (
    <Formik
      initialValues={{
        ...{
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          country: '',
          phoneNumber: '',
          comment: '',
          hearAboutUs: '',
          reference: referenceFieldValue,
        },
        ...getHoneypotFieldsWithInitialValue(honeypot),
      }}
      validationSchema={recruitmentFormValidationSchema}
      onSubmit={(values, actions) => {
        if (values) {
          handleFormSubmit({
            url: submitEndpoint,
            values,
            formName,
            hiddenFieldsValues: deserializeHiddenFields(hiddenFields),
          }).then(() => {
            actions.setSubmitting(false);
            setIsSubmissionOk(true);
          });
        }
      }}
    >
      {({ handleSubmit, handleChange, errors, values, touched, isValid, isSubmitting }) => (
        <Form name={FORM_NAME.RECRUITMENT} onSubmit={handleSubmit} honeypot={honeypot}>
          <input type="hidden" name="form-name" value={FORM_NAME.RECRUITMENT} />
          <Input
            label="Name"
            value={values.firstName}
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            errorMessage={touched.firstName && errors.firstName}
            required
          />
          <Input
            name="lastName"
            value={values.lastName}
            placeholder="Last Name"
            onChange={handleChange}
            errorMessage={touched.lastName && errors.lastName}
          />
          <Input
            name="email"
            value={values.email}
            label="Email"
            onChange={handleChange}
            errorMessage={touched.email && errors.email}
            required
          />
          <Input
            name="role"
            value={values.position}
            label="Role you are applying for"
            onChange={handleChange}
            required
          />
          <Select
            value={values.country}
            label="Current country of residence"
            name="country"
            options={countries}
            errorMessage={touched.country && errors.country}
            onChange={handleChange}
            required
            defaultOption="- Select a residence country -"
          />
          <Input name="phoneNumber" value={values.phoneNumber} label="Phone number" onChange={handleChange} />
          <Input type="hidden" name="reference" value={values.referenceFieldValue} />
          <TextArea
            name="comment"
            value={values.comment}
            label="Anything else you want to tell us?"
            onChange={handleChange}
            errorMessage={touched.comment && errors.comment}
          />
          <Select
            value={values.hearAboutUs}
            label="How did you hear about us?"
            name="hearAboutUs"
            options={toSelectOptions(hearAboutUsSource)}
            errorMessage={touched.hearAboutUs && errors.hearAboutUs}
            onChange={handleChange}
            defaultOption="- Select how you heard about us -"
          />
          <div className="block w-full mt-8">
            {!isSubmissionOk && (
              <Captcha token={token} setToken={setToken} isSubmitting={isSubmitting} isValid={isValid} />
            )}
            {isSubmissionOk && (
              <div className="mt-8">
                <ReactMarkdown source={successMessage} />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
