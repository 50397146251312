import React from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';

import { ButtonBar } from './button-bar';
import { Icon } from '../icon';
import { formattedDate } from '../utils';
import { Link } from '../link';

const ListItem = ({
  imageIcon,
  title,
  contentBody,
  metadata,
  author,
  backgroundColor,
  showListAsGrid,
  isGradient,
  isArticle,
  url,
  learnMoreLink,
  linkToVideo,
  itemClassName,
  showContent,
}) => {
  const backgroundColorMap = {
    magenta: '#EAD9E3',
    red: '#FADDDC',
    green: '#E1F7E6',
    blue: '#DEDCFF',
  };

  return (
    <li className={cx(itemClassName, `max-w-lg p-2 list-item mb-4 ${showListAsGrid ? 'md:w-1/2' : 'md:w-1/3'}`)}>
      <Link {...(url && { to: url })} className="list-container">
        <div
          className={cx({
            'shadow-lg hover:shadow-2xl': metadata?.length > 0 && !isArticle,
            'flex flex-row items-start': showListAsGrid,
          })}
        >
          {imageIcon && (
            <div
              className={cx(
                'relative',
                {
                  'mr-4': showListAsGrid,
                },
                backgroundColor && isGradient && `bg-gradient-to-b from-${backgroundColor}-100 to-white`,
              )}
              style={
                backgroundColor
                  ? {
                      backgroundColor: backgroundColorMap[backgroundColor],
                    }
                  : {}
              }
            >
              <img
                src={imageIcon.url}
                className={cx({
                  'mx-auto': !isArticle && !linkToVideo,
                  'mr-auto ml-4 icon': isArticle,
                  'w-full': linkToVideo,
                  icon: !metadata,
                })}
                aria-hidden="true"
                alt=""
              />
              {linkToVideo && (
                <div className="h-16 w-16 absolute bottom-0 left-0 bg-white">
                  <div className="btn-play" />
                </div>
              )}
            </div>
          )}
          <div
            className={cx('relative', {
              'border-t-2 border-gray-100': isGradient,
              'text-center': metadata?.length === 0 && !showListAsGrid,
              'm-2 p-2': !showListAsGrid,
              'min-height': metadata,
            })}
          >
            <h4
              className={cx('font-semibold mb-4', {
                'text-blue-500': showListAsGrid && !imageIcon,
                'underline hover:no-underline': showListAsGrid && !imageIcon && url,
              })}
            >
              {title}
            </h4>
            {contentBody && (showContent || showListAsGrid) && (
              <div className="markdown-body text-sm ">
                <ReactMarkdown source={contentBody} />
                {learnMoreLink && (
                  <a className="underline text-blue-500" href={learnMoreLink} target="_blank" rel="noreferrer noopener">
                    Learn more ↗
                  </a>
                )}
              </div>
            )}
            {author && <span className="text-xs my-4 block">{author}</span>}

            {metadata &&
              metadata?.map(({ date, organisation, timeToRead, id }) => (
                <div className="text-xs items-center flex flex-wrap mt-8 pb-4 absolute bottom-0" key={id}>
                  <Icon name="clock" fill="none" />
                  {timeToRead && <span className="ml-1 block">{timeToRead} |</span>}
                  {organisation && <span className="block ml-1"> {organisation} |</span>}
                  {date && (
                    <span className="block ml-1" style={{ color: '#787878' }}>
                      {formattedDate(date)}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Link>
    </li>
  );
};

export const List = ({ content, showContent }) => {
  if (!content) return null;

  const { listItems, itemClassName, showListAsGrid, linkToPage, itemsToShow, button } = content;

  return (
    <div
      className={cx('list w-full mt-0', {
        'mt-8': linkToPage,
        'px-6 lg:px-20': !showListAsGrid,
      })}
    >
      <ul
        className={cx('flex flex flex-wrap flex-col md:flex-row -m-2', {
          'mt-12 md:mt-0': showListAsGrid,
          'max-w-5xl mx-auto': !showListAsGrid,
        })}
      >
        {listItems &&
          listItems.map((item, i) => {
            if (itemsToShow && i > itemsToShow - 1) return null;

            return (
              <ListItem
                {...item}
                itemClassName={itemClassName}
                key={item.id}
                showListAsGrid={showListAsGrid}
                showContent={showContent}
              />
            );
          })}
      </ul>
      {button?.buttons && (
        <div className="text-center mx-auto mt-12">
          {' '}
          <ButtonBar {...button} />
        </div>
      )}
    </div>
  );
};
