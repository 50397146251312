import React from 'react';

import ReactMarkdown from 'react-markdown';
import { Link } from './link';

export const AlertBarModal = ({ url, contentBlock, alertType, text, renderIcon }) => {
  if (!contentBlock) return null;

  const mapAlertTypeToColor = {
    info: 'bg-blue-100 border-t-3 border-blue-500',
    warning: 'bg-orange-100 border-t-3 border-orange-500',
    error: 'bg-red-100 border-t-3 border-red-500',
  };

  const mapIconToAlertType = {
    error: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
          stroke="#333132"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 8V12" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 16H12.01" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
    info: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="#333132"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 16V12" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 8H12.01" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
    warning: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.29 3.85996L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7238C2.83871 20.9009 3.18082 20.9961 3.53002 21H20.47C20.8192 20.9961 21.1613 20.9009 21.4623 20.7238C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.85996C13.5318 3.56607 13.2807 3.32308 12.9812 3.15444C12.6817 2.98581 12.3438 2.89722 12 2.89722C11.6563 2.89722 11.3184 2.98581 11.0188 3.15444C10.7193 3.32308 10.4683 3.56607 10.29 3.85996V3.85996Z"
          stroke="#333132"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 9V13" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 17H12.01" stroke="#333132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  };

  return (
    <Link to={url} openInNewWindow className="shared-alert-bar">
      <dialog
        open
        className={`bg-magenta-100 fixed relative alert h-20 lg:h-12 z-50 w-full flex items-center content-center justify-center text-xs  ${mapAlertTypeToColor[alertType]} lg:border-0`}
      >
        {renderIcon && (
          <div className="flex items-center content-center mb-4 lg:mb-0">
            <div className="inline lg:hidden">{mapIconToAlertType[alertType]}</div>
            <p className="mobile-title ml-4 lg:ml-0">
              <em>{text}</em>
            </p>
          </div>
        )}

        <ReactMarkdown source={contentBlock} />
      </dialog>
    </Link>
  );
};
