import React from 'react';
import Hcaptcha from '@hcaptcha/react-hcaptcha';

import { Button } from '../shared/button';

export const Captcha = ({ token, setToken, isSubmitting, isValid }) => (
  <>
    <Hcaptcha sitekey="75765593-fe01-40eb-9c3a-2f34443cba7a" onVerify={setToken} />

    <Button type="submit" buttonStyle="light-primary" className="mt-8" disabled={!isValid || isSubmitting || !token}>
      Submit
    </Button>
  </>
);
