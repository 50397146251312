import React, { ComponentType, ReactNode, MouseEvent, PropsWithChildren, isValidElement, CSSProperties } from 'react';
import cx from 'classnames';
import { isFragment } from 'react-is';

import { Icon } from '../Icon';
import * as styles from './AccordionItem.module.css';
import { AccordionItemError } from './Accordion.types';

type BaseProps = {
  children: ReactNode;
};

type AccordionHeaderProps = BaseProps & {
  className?: string;
  headerContentClassName?: string;
  activeClassName?: string;
  activeStyle?: CSSProperties;
  activeHeaderContentClassName?: string;
};

export const AccordionHeader = ({ children }: AccordionHeaderProps) => <>{children}</>;
export const AccordionPanel = ({ children }: BaseProps) => <>{children}</>;

function getChildrenFromFragment(children: ReactNode): ReactNode {
  return isFragment(children) ? getChildrenFromFragment(children.props.children) : children;
}

function getChildFromChildren<T extends BaseProps>(compareComponent: ComponentType<T>) {
  return function (children: ReactNode) {
    const unwrappedChildren = getChildrenFromFragment(children);
    const childrenCollection = React.Children.toArray(unwrappedChildren);

    const foundChild = childrenCollection.find((child) => {
      // Check the child is a valid React element
      if (!isValidElement<T>(child)) {
        return false;
      }

      // Check the child is the wanted component instance
      return child.type === compareComponent;
    });

    const childProps = isValidElement(foundChild) ? foundChild.props : null;

    return [foundChild, childProps];
  };
}

const getHeaderFromChildren = getChildFromChildren<AccordionHeaderProps>(AccordionHeader);
const getPanelFromChildren = getChildFromChildren<BaseProps>(AccordionPanel);

type AccordionItemProps = PropsWithChildren<{ expanded?: boolean; onHeaderClick?: (event: MouseEvent) => void }>;

export const PrivateAccordionItem = ({ children, expanded = false, onHeaderClick }: AccordionItemProps) => {
  const [header, headerProps] = getHeaderFromChildren(children);
  if (undefined === header) {
    throw new AccordionItemError('<AccordionHeader /> is required by <AccordionItem />');
  }

  const [panel] = getPanelFromChildren(children);
  const hasPanel = undefined !== panel;
  const clickHandler = (event: MouseEvent) => {
    if (hasPanel) {
      onHeaderClick?.(event);
    }
  };

  return (
    <div>
      <div
        className={cx(
          {
            'cursor-pointer': hasPanel,
          },
          headerProps?.className,
          expanded && headerProps?.activeClassName,
        )}
        style={expanded ? headerProps?.activeStyle : undefined}
        onClick={clickHandler}
      >
        <div
          className={cx(
            styles.headerContent,
            headerProps?.headerContentClassName,
            expanded && headerProps?.activeHeaderContentClassName,
          )}
        >
          {header}
          {hasPanel && <Icon.Chevron className={cx(styles.expandIndicator, expanded && styles.expanded)} />}
        </div>
      </div>
      {expanded && <div>{panel}</div>}
    </div>
  );
};
