import React, { ReactNode } from 'react';

import { TopLevelComponents as SharedComponents } from '@mattr/shared-components';
import { ShowcaseHero } from '@mattrglobal/cms-components';
import { TermHtmlModule } from './html-term-module';
import config from '../../../config/webConfig';
import { PageContent } from '../../types/fragments.types';

const contentTypes = {
  ...SharedComponents,
  term_html_module: TermHtmlModule,
  showcase_hero: ShowcaseHero,
};

export const cmsContentMap = (content?: PageContent | null): ReactNode[] => {
  if (!content) {
    return [];
  }

  return content.map((module, i) => {
    if (!module) {
      return <></>;
    }

    const apiKey = ('model' in module && module.model?.apiKey) || '';
    const TagName = contentTypes[apiKey];

    if (!TagName) {
      return <></>;
    }

    const key = `${TagName.name}-${i}`;
    // @ts-ignore - Something funny with the definition in Dato?
    const id = module.id;

    if (config.SHOW_MODULE_NAMES) {
      return (
        <React.Fragment key={key}>
          <div style={{ borderWidth: '2px', borderColor: 'purple' }}>
            <div style={{ color: 'purple' }}>
              <span>{apiKey}</span>
            </div>
            <div>
              <TagName allPageModules={content} key={id} {...module} />
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={key}>
        <TagName allPageModules={content} key={id} {...module} />
      </React.Fragment>
    );
  });
};
