import React from 'react';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { BACKGROUND_COLOR_TO_CLASS } from '../../tools/utilities/constants';

import { MarkdownBody } from '../shared/markdown-body';
import { ButtonBar } from '../shared/button-bar';

export const ImageWithText = ({ content, anchorLink }) => {
  const breakpoints = useBreakpoint();

  if (!content) return null;

  const { image, text, mobileImage, backgroundColour, buttons, superscript } = content;

  const textFragment = (
    <div className="aside-text px-6 lg:px-20 lg:w-4/12">
      {(text || superscript) && <MarkdownBody superscript={superscript} content={text} />}

      <ButtonBar buttons={buttons?.buttons} />
    </div>
  );

  const imageStyle = {};

  const imageToShow = breakpoints.sm ? mobileImage ?? image : image;

  if (imageToShow) {
    imageStyle.backgroundImage = `url(${imageToShow?.url})`;
    imageStyle.paddingBottom = `${imageToShow?.height}px`;
  }

  const backgroundImageClassName = cx('aside-image mt-6 lg:mt-0', imageToShow?.customData?.className ?? '');

  const imageFragment = <div className={backgroundImageClassName} style={imageStyle} />;

  return (
    <section
      className={`cms-module flex justify-center image-with-text ${BACKGROUND_COLOR_TO_CLASS[backgroundColour]}`}
      {...(anchorLink ? { id: anchorLink } : {})}
    >
      <div className="max-w-6xl w-full">
        <div className="lg:flex lg:items-center">
          {textFragment} {imageFragment}
        </div>
      </div>
    </section>
  );
};

export const imageWithTextQueryFragment = graphql`
  fragment ImageWithText on DatoCmsImageWithText {
    anchorLink
    content {
      buttons {
        buttons {
          id
          title
          href
          openInNewWindow
          buttonStyle
        }
      }
      superscript
      text
      superscript
      backgroundColour
      image {
        url
        height
        customData
      }
      mobileImage {
        url
        height
        customData
      }
    }
    model {
      apiKey
    }
  }
`;
