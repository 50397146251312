import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';

import './src/styles/styles.css';
import LearnPageTemplate from './src/templates/page-template';

export const onClientEntry = () => {
  smoothscroll.polyfill();
};

export const wrapPageElement = ({ element, props }) => {
  if (element?.type?.name === LearnPageTemplate.name) {
    return <LearnPageTemplate {...props} />;
  } else {
    return <>{element}</>;
  }
};
