import React from 'react';

import SVGs from '../tools/utilities/svg-content';

export const toDarkIcon = (iconName) => iconName.replace(/light/, 'dark');
export const toLightIcon = (iconName) => iconName.replace(/dark/, 'light');

export const Icon = ({ name, className = 'svg' }) => {
  const icon = SVGs[name];

  return (
    <span className={className} data-icon={name}>
      {icon}
    </span>
  );
};
