import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import ClipboardIcon from '../../../../svg-icons/clipboard.svg';

export const CodeSnippet = ({ language, code }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="code-snippet p-4 mt-4">
      <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
        <div>
          <div className="code-snippet-language flex justify-between">
            <sup className="uppercase font-semibold tracking-wider block text-sm md:text-xs">{language}</sup>
            <div className="code-snippet-button">
              <span className="sr-only">Copy to clipboard.</span>
              <ClipboardIcon className="h-5 w-5" />
            </div>
          </div>
          <SyntaxHighlighter showLineNumbers={!!language} language={language || 'plaintext'} style={github}>
            {code}
          </SyntaxHighlighter>
        </div>
      </CopyToClipboard>

      {copied && 'Copied to clipboard.'}
    </div>
  );
};
