import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { Container } from '../../layout/container';

export const TextContent = ({ text, superscript, leadingText }) => (
  <div className="bg-gradient-to-b from-white to-gray-50">
    <Container>
      <div className="flex justify-center">
        <div className="max-w-screen-md cms-module relative">
          <div className="markdown-body">
            {superscript && (
              <sup className="uppercase font-semibold tracking-wider text-sm md:text-xs">{superscript}</sup>
            )}

            {leadingText && <ReactMarkdown className="leading text-xl font-semibold">{leadingText}</ReactMarkdown>}

            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export const textContentQueryFragment = graphql`
  fragment TextContent on DatoCmsTextContent {
    text
    superscript
    leadingText
    model {
      apiKey
    }
  }
`;
