import React from 'react';

import { Link } from '@mattr/shared-components';
import FileIcon from '../../images/file-text.svg';

type LinkProps = {
  title: string;
  href: string;
  id: string;
};

type TermPropTypes = {
  content: {
    title: string;
    links: Array<LinkProps>;
  };
};

export const ListOfTerms = ({ content: { title, links } }: TermPropTypes): JSX.Element => {
  return (
    <div className="list-of-terms py-8">
      <h2 className="pb-8">{title}</h2>
      <div className="flex flex-wrap w-full">
        {links.map(
          ({ title: linkTitle, href, id }: LinkProps): JSX.Element => (
            <Link
              className="block w-full lg:w-1/3 mb-8 underline hover:no-underline text-blue-500 font-semibold"
              to={href}
              key={id}
            >
              <FileIcon className="mb-2" />
              {linkTitle}
            </Link>
          ),
        )}
      </div>
    </div>
  );
};
