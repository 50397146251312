import React from 'react';

import { TickList } from './tick-list';
import { List } from './list';
import { MarkdownBody } from './markdown-body';
import { LogoGrid } from './logo-grid';
import { ButtonBar } from './button-bar';

export const ContentSection = ({ content, className = 'content-section lg:flex-1 lg:mr-20' }) => {
  if (!content) return null;

  const { buttonBar, image, list, content: contentChild, superscript, logoGrid } = content;

  return (
    <div className={className}>
      <MarkdownBody content={contentChild} superscript={superscript} />

      {image?.url ? (
        <img src={image?.url} alt={image?.alt} />
      ) : (
        <>
          {list && list.tickList && <TickList {...list} />}

          {list?.listItems && <List {...list} />}
        </>
      )}
      {buttonBar && <ButtonBar {...buttonBar} />}
      {logoGrid && logoGrid.length > 0 && <LogoGrid logos={logoGrid} />}
    </div>
  );
};
