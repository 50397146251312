import { nanoid } from 'nanoid';

export const encode = (data: Record<string, string | number | boolean>) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export type FormSubmitOptions = {
  url?: string;
  values: Record<string, unknown>;
  formName: string;
  hiddenFieldsValues?: Record<string, string>;
};

export const handleFormSubmit = async ({ values, formName, url, hiddenFieldsValues }: FormSubmitOptions) => {
  return fetch(url || '/', {
    method: 'POST',
    mode: 'no-cors', // This is to be used by form submit, so we can safely ignore cors as in tradition, actions of form ignore cors checks.
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': formName, ...{ ...values, ...hiddenFieldsValues }, id: nanoid() }),
  })
    .then((response) => {
      // in cases of cors issues which we choose to ignore, we will not have response,
      // as it will be blocked by the browser, so if we didn't receive any response, we choose to think it is okay.
      if (!response || response.ok) {
        return response;
      }
      throw new Error(`Error submitting form: ${response && response.text}`);
    })
    .catch((error) => console.error(error)); // Todo proper error handling.
};

export const toSelectOptions = (options: Array<string>) => options.map((option) => ({ label: option }));

type FieldKey = keyof typeof fieldsMap;
type Fields = Record<FieldKey, string>;
type PardotFieldKey = typeof fieldsMap[FieldKey];
type PardotFields = Record<PardotFieldKey, Fields[FieldKey]>;

// Transform contact form fields to meet Pardot requirements
const fieldsMap = {
  firstName: 'first_name',
  lastName: 'last_name',
  organisation: 'company',
  organisationWebsite: 'url',
  roleType: '00N2w00000FageS',
  phoneNumber: 'phone',
  whichSolution: '00N2w00000JwytS',
  tellUsMore: '00N2w00000JwytR',
  mailingList: '00N2w00000JwytQ',
};

export const toPardotContactFormFields = (fields: Fields): PardotFields => {
  // Transform checkbox on/off to true/false
  // The value input is ['on'] if checkbox checked
  // or empty string if not checked. Better to refactor
  // checkbox component later.
  fields['mailingList'] = String(fields['mailingList']?.[0] === 'on');

  return (Object.keys(fields) as Array<FieldKey>).reduce((formFields, key) => {
    const newKey = fieldsMap[key] ?? key;

    return { ...formFields, ...{ [newKey]: fields[key] } };
  }, {});
};

export const deserializeHiddenFields = (
  hiddenFields: Array<{ name: string; value: string }> | undefined,
): Record<string, string> =>
  hiddenFields
    ? hiddenFields.reduce((fieldsValues, field) => ({ ...fieldsValues, ...{ [field.name]: field.value } }), {})
    : {};
