import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from '@mattr/shared-components';

const Step = ({ contentBody, metadata }) => {
  const mapIconToContentType = {
    video: (
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.75 3.46891L12 6.5L6.75 9.53109L6.75 3.46891Z" stroke="#333132" />
        <rect x="0.5" y="0.5" width="16" height="12" stroke="#333132" />
      </svg>
    ),
    read: (
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="16" height="12" stroke="#333132" />
        <line x1="3" y1="3.5" x2="10" y2="3.5" stroke="#333132" />
        <path d="M3 7.5L12 7.5" stroke="#333132" />
        <path d="M3 5.5L5.25 5.5H7.5" stroke="#333132" />
      </svg>
    ),
    interactive: (
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="7" y1="9.5" x2="14" y2="9.5" stroke="#333132" />
        <path
          d="M8.5 5.60637L3.5 8.18025V8.09607L7.76613 5.95405L8.5 5.58558V5.60637ZM8.5 5.39363V5.42885L7.76613 5.06038L3.5 2.91837V2.81975L8.5 5.39363Z"
          stroke="#333132"
        />
        <rect x="0.5" y="0.5" width="16" height="12" stroke="#333132" />
      </svg>
    ),
  };

  return (
    <li className="step relative pl-10 mb-12">
      <div className="markdown-body learn-markdown-body">
        <ReactMarkdown source={contentBody} />
      </div>
      <div>
        {metadata.map(({ contentTypes, timeToRead, id }) => (
          <ul className="flex text-xs" key={`StepMetadata-${id}`}>
            <li className="flex mr-4 items-center">
              <span className="mr-1">
                <svg width="15" height="15" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="none"
                    d="M8.67 16.34C12.906 16.34 16.34 12.906 16.34 8.67C16.34 4.43398 12.906 1 8.67 1C4.43398 1 1 4.43398 1 8.67C1 12.906 4.43398 16.34 8.67 16.34Z"
                    stroke="#333132"
                    strokeWidth="1.18"
                  />
                  <path
                    d="M8.66992 8.67002L11.7376 6.90002M8.66992 8.67002V2.77002V8.67002Z"
                    stroke="#333132"
                    strokeWidth="1.18"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              {timeToRead}
            </li>
            {contentTypes.map(({ contentType, id }) => (
              <li className="flex capitalize mr-3 items-center" key={`StepContentType-${id}`}>
                <span className="mr-1">{mapIconToContentType[contentType]}</span>
                {contentType}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </li>
  );
};

export const Steps = ({ content: { steps, linkToPage } }) => {
  return (
    <div>
      <ul className="steps relative">
        {steps.map((item, i) => {
          if (i <= 2) return <Step key={`Step-${item.id}`} {...item} />;
        })}
      </ul>
      <div className="pl-10">
        <Link to={linkToPage.href} {...linkToPage} className="link font-semibold">
          {linkToPage.title}
        </Link>
      </div>
    </div>
  );
};
