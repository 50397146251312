import React from 'react';
import { graphql } from 'gatsby';
import he from 'he';

type TermHtmlModuleProps = {
  html: string;
};

export const TermHtmlModule = ({ html }: TermHtmlModuleProps): React.ReactElement => {
  return <div className="term-html-module" dangerouslySetInnerHTML={{ __html: he.decode(html) }} />;
};

export const HtmlTermModuleFragment = graphql`
  fragment TermHtmlModule on DatoCmsTermHtmlModule {
    html
    model {
      apiKey
    }
  }
`;
