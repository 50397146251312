import React from 'react';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const AnimationHero = ({ anchorLink, largeBackgroundImage, smallBackgroundImage }) => {
  const breakpoints = useBreakpoint();

  const imageStyle = {};
  const imageToShow = !breakpoints.sm ? largeBackgroundImage : smallBackgroundImage;

  if (imageToShow) {
    imageStyle.backgroundImage = `url(${imageToShow?.url})`;
  }

  return (
    <section className="animation-hero" {...(anchorLink ? { id: anchorLink } : {})}>
      <div className="background-image" style={imageStyle}>
        <h2 className="sr-only">An integrated set of capabilities on a platform built for scale</h2>
      </div>
    </section>
  );
};

export const animationHeroQueryFragment = graphql`
  fragment AnimationHero on DatoCmsAnimationHero {
    anchorLink
    animation {
      url
      alt
    }
    leftImage {
      url
      alt
    }
    centerImage {
      url
      alt
    }
    rightImage {
      url
      alt
    }
    text
    largeBackgroundImage {
      url
      height
      alt
    }
    smallBackgroundImage {
      url
      height
      alt
    }
    model {
      apiKey
    }
  }
`;
