import { Script as GatsbyScript } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

export enum SnippetPosition {
  HEAD,
  BODY,
}

export type Snippet = {
  id: string;
  script: string;
  position: SnippetPosition;
  type: string;
  src?: string;
};

type CustomSnippetsProps = {
  snippetPosition: SnippetPosition;
  snippets: Snippet[];
};
export const CustomSnippets = ({ snippetPosition, snippets }: CustomSnippetsProps): JSX.Element => {
  const renderSnippetWithinElement = (snippet: Snippet, element: JSX.Element) =>
    snippet.src ? (
      <element.type id={snippet.id} key={snippet.id} type={snippet.type} src={snippet.src} />
    ) : (
      <element.type id={snippet.id} key={snippet.id} type={snippet.type}>
        {snippet.script}
      </element.type>
    );

  const getSnippetsByPosition = (position: SnippetPosition) =>
    snippets.filter((snippet) => snippet.position === position);

  const renderScriptsForHead = () => {
    const headSnippets = getSnippetsByPosition(SnippetPosition.HEAD);

    return <Helmet>{headSnippets.map((snippet) => renderSnippetWithinElement(snippet, <script />))}</Helmet>;
  };

  const renderScriptsForBody = () => {
    const bodySnippets = getSnippetsByPosition(SnippetPosition.BODY);

    return bodySnippets.map((snippet) => renderSnippetWithinElement(snippet, <GatsbyScript />));
  };

  return (
    <>
      {snippetPosition === SnippetPosition.HEAD && renderScriptsForHead()}
      {snippetPosition === SnippetPosition.BODY && renderScriptsForBody()}
    </>
  );
};
