import React, { useState } from 'react';
import { Field } from 'formik';
import cx from 'classnames';

import { Icon } from '../icon';

export const Input = ({
  name,
  placeholder,
  onChange,
  errorMessage,
  label,
  id,
  required,
  type = 'text',
  message,
  value,
  dataTestId,
}) => {
  const [isIconClicked, setIsIconClicked] = useState(false);

  return (
    <Field onChange={onChange} name={name}>
      {({ field }) => (
        <div className="mb-4">
          {label && (
            <label htmlFor={name} className="block text-sm font-semibold font-medium text-black pb-1">
              {label} {required && <span className="text-magenta-500">*</span>}
            </label>
          )}
          <div className="my-1 relative">
            <input
              {...field}
              className={cx('block w-full sm:text-sm border border-black pl-2 py-2', {
                'border-red-500': errorMessage,
              })}
              placeholder={placeholder}
              aria-labelledby={name}
              id={id}
              type={type}
              value={value}
              data-testid={dataTestId}
            />
            {errorMessage && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <div
                  className={cx('alert-icon', { clicked: isIconClicked })}
                  onClick={() => setIsIconClicked(!isIconClicked)}
                >
                  <Icon name="alert-circle" fill="none" />
                </div>
                <span className={cx('alert-message', { clicked: isIconClicked })}>{errorMessage}</span>
              </div>
            )}
          </div>
          {message && <span className="text-xs">{message}</span>}
        </div>
      )}
    </Field>
  );
};

export const TextArea = ({ onChange, name, placeHolder, errorMessage, label, required, rows = 5 }) => {
  const [isIconClicked, setIsIconClicked] = useState(false);

  return (
    <Field onChange={onChange} name={name}>
      {({ field }) => (
        <div className="relative">
          {label && (
            <label htmlFor={name} className="block text-sm font-semibold font-medium text-gray-700">
              {label} {required && <span className="text-magenta-500">*</span>}
            </label>
          )}
          <textarea
            rows={rows}
            className={`block w-full sm:text-sm md:text-base sm:leading-5 border py-3 pl-4 mb-4 ${
              errorMessage ? 'border-red-500 focus:border-red-500' : 'border-black'
            }`}
            placeholder={placeHolder}
            {...field}
          />
          {errorMessage && (
            <div className="absolute bottom-0 right-0 pr-3 flex">
              <div
                className={cx('text-area-icon', {
                  clicked: isIconClicked,
                })}
                onClick={() => setIsIconClicked(!isIconClicked)}
              >
                <Icon name="alert-circle" fill="none" />
              </div>
              <span
                className={cx('text-area-alert-message', {
                  clicked: isIconClicked,
                })}
              >
                {errorMessage}
              </span>
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

export const Checkbox = ({ className, onChange, children, name, errorMessage, id }) => (
  <div className={cx('checkbox', className)}>
    <input type="checkbox" onChange={onChange} name={name} id={id} className="pr-3" />
    {children}
    {errorMessage && <span className="block text-magenta-500 mb-2 text-xs">{errorMessage}</span>}
  </div>
);
