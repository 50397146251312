import appConfig from './appConfig';

export default {
  SHOW_MODULE_NAMES: appConfig.GATSBY_SHOW_MODULE_NAMES,
  IS_DEV_ENV: appConfig.GATSBY_ACTIVE_ENV === 'development' || process.env.NODE_ENV === 'development',

  // GATSBY_WEB_REDIRECTS is dynamically generated in gatsby-node.js
  WEB_REDIRECTS: process.env.GATSBY_WEB_REDIRECTS,

  // Algolia site search
  ALGOLIA_APP_ID: appConfig.GATSBY_ALGOLIA_APP_ID,
  ALGOLIA_PUBLIC_SEARCH_KEY: process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_KEY,
  ALGOLIA_INDEX_NAME: appConfig.GATSBY_ALGOLIA_INDEX_NAME,
  SNIPPETS_ENV: appConfig.SNIPPETS_ENV,
};
