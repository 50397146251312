import React from 'react';
import { graphql } from 'gatsby';
import { Field, FieldProps } from 'formik';

import { HoneypotFragment } from './Honeypot.types';
import * as styles from './Honeypot.module.css';

export type HoneypotProps = {
  data: Array<HoneypotFragment>;
};

export const Honeypot = ({ data }: HoneypotProps) => (
  <>
    {data.length > 0
      ? data.map(({ fieldId, fieldName, label }) => (
          <Field key={fieldId} name={fieldName}>
            {({ field }: FieldProps) => (
              <div className={styles.honeypot}>
                <label htmlFor={fieldId}>{label}</label>
                <input type="text" id={fieldId} {...field} />
              </div>
            )}
          </Field>
        ))
      : null}
  </>
);

export const query = graphql`
  fragment Honeypot on DatoCmsFormHoneypot {
    fieldId
    fieldName
    label
  }
`;
