import { createConnector } from 'react-instantsearch-dom';

import { categoryToPillname } from './utils';
import { hitsPerPage } from './constants';

type ExposedProps = {
  onPageChange?: () => void;
};

type ProvidedProps = {
  hits: Array<Hit>;
  page: number | string;
  pageCount: number;
  onPageChange?: () => void;
  searching: boolean;
  totalResults: number;
  query: string | undefined;
  filterName: string | undefined;
};

export const filterConnector = createConnector<ProvidedProps, ExposedProps>({
  displayName: 'SearchResults',

  getProvidedProps(props, searchState, searchResults) {
    let hits = searchResults.results?.hits || [];

    const { menuFilter } = searchState;
    let filterName: string | undefined;

    if (menuFilter && menuFilter !== 'All') {
      const filters = menuFilter.split(' > ');
      const topCategory = filters[0];
      const subCategory = filters[1];
      hits = hits.filter((h) => {
        if (h.hierarchicalCategories.lvl0 != filters[0]) {
          return false;
        }

        if (filters.length > 1 && h.hierarchicalCategories.lvl1 != menuFilter) {
          return false;
        }

        return true;
      });

      filterName = categoryToPillname(topCategory).toLowerCase();
      if (subCategory) {
        filterName = `${filterName} > ${subCategory.toLowerCase()}`;
      }
    }

    const pageCount = Math.ceil(hits.length / hitsPerPage);
    const totalResults = hits.length;

    const currentPage: number = searchState.page || 1;
    const start = (currentPage - 1) * hitsPerPage;
    hits = hits.slice(start, start + hitsPerPage);
    const result: ProvidedProps = {
      hits,
      page: currentPage,
      pageCount,
      onPageChange: props.onPageChange,
      searching: searchResults.searching,
      totalResults,
      query: searchState.query,
      filterName,
    };

    return result;
  },

  refine(props, searchState, update) {
    return {
      ...searchState,
      ...update,
    };
  },
});
