import { Snippet, SnippetPosition } from '@mattrglobal/components';
import pardotTracking from './pardot-tracking';

const textJavascript = 'text/javascript';
export const QaSnippets: Snippet[] = [
  {
    id: 'pardot-tracking',
    script: pardotTracking,
    position: SnippetPosition.BODY,
    type: textJavascript,
  },
];
