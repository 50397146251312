import { Link } from '@mattr/shared-components';
import React from 'react';
import { createConnector, Highlight } from 'react-instantsearch-dom';

import { SiteNames } from './constants';
import { categoryToPillname, getFriendlyCategoryName, getSubCategoryName } from './utils';
import { ViewMore } from './view-more';
import { Excerpt } from './excerpt';

const resultsConnector = createConnector({
  displayName: 'SearchResults',

  getProvidedProps(props, searchState, searchResults) {
    const hits = searchResults.results?.hits || [];

    return { hits, searching: searchResults.searching, query: searchState.query };
  },
});

export const SearchResultsBox = resultsConnector(({ hits = [], searching, query }) => {
  const noResultsFound = !searching && query && query.length > 2 && !hits.length;
  const hasResults = hits.length > 0;

  if (!hasResults && !noResultsFound) {
    return null;
  }

  return (
    <div className="ais-SearchResultsBox-Container ais-Hit-Container-Small flex flex-col">
      {noResultsFound && (
        <>
          <h1 className="font-semibold mb-1 mt-3">No results found :(</h1>
          <div className="text-sm">
            <span>Our search engine can&rsquo;t find the information you&rsquo;re after... please </span>
            <Link to="https://mattr.global/contact/" attrs={{ target: '_blank' }}>
              <span className="text-blue-500 hover:bg-blue-100 hover:outline-blue-100 underline">contact us</span>
            </Link>
            <span> and we&rsquo;d be more than happy to help.</span>
          </div>
        </>
      )}
      {hasResults && (
        <>
          {hits.map((hit) => {
            const topCategory = hit.hierarchicalCategories.lvl0;
            const subCategory = hit.hierarchicalCategories.lvl1;

            let categoryName = categoryToPillname(topCategory);
            const hasSubCategory = SiteNames[getFriendlyCategoryName(topCategory)].subcategories;
            if (hasSubCategory) {
              categoryName = `${categoryName} > ${getSubCategoryName(subCategory).toUpperCase()}`;
            }

            return (
              <React.Fragment key={hit.url}>
                <a href={hit.url} className="ais-SearchResult-Link">
                  <div>
                    <div className="ais-SearchResult-Link-Title">
                      <Highlight hit={hit} attribute="title" tagName="mark" />
                    </div>
                    <div className="ais-SearchResult-Link-Excerpt">
                      <Excerpt hit={hit} />
                    </div>
                  </div>
                </a>
                <div className="ais-SearchResult-Link-Category">{categoryName}</div>
                <hr className="ais-SearchResult-Link-Divider" />
              </React.Fragment>
            );
          })}
          <ViewMore />
        </>
      )}
    </div>
  );
});
