import { graphql } from 'gatsby';
import React from 'react';
import { cmsContentMap } from '../components/cms/cms-content-map';

import { Layout } from '../components/layout/layout';
import type { LearnPageQuery } from '../types/fragments.types';

type LearnPageProps = { data: LearnPageQuery };

export default function LearnPageTemplate({
  data: { breadcrumbs, prevNextButtons, page, sidebar },
}: LearnPageProps): JSX.Element {
  return (
    <Layout
      slug={page?.slug}
      prevNextButtons={prevNextButtons}
      breadcrumbs={breadcrumbs}
      seoMetaTags={page?.seoMetaTags}
      sidebar={sidebar}
      metadataSchema={page?.metadataSchema}
    >
      {cmsContentMap(page?.content)}
    </Layout>
  );
}

export const pageNavigationFieldsFragment = graphql`
  fragment PageNavigationFields on DatoCmsLearnPage {
    id
    title
    shortTitle
    slug
    position
    showInNavigation
    content {
      __typename
    }
  }
`;

export const learnPageQuery = graphql`
  query LearnPage($slug: String!, $sectionPath: String!) {
    breadcrumbs: datoCmsLearnPage(slug: { eq: $slug }) {
      ...PageNavigationFields
      treeParent {
        ...PageNavigationFields
        treeParent {
          ...PageNavigationFields
          treeParent {
            ...PageNavigationFields
            treeParent {
              ...PageNavigationFields
            }
          }
        }
      }
    }

    prevNextButtons: datoCmsLearnPage(slug: { eq: $slug }) {
      slug
      treeParent {
        treeChildren {
          ...PageNavigationFields
        }
      }
    }

    sidebar: allDatoCmsLearnPage(filter: { slug: { eq: $sectionPath } }) {
      edges {
        node {
          treeChildren {
            ...PageNavigationFields
            treeChildren {
              ...PageNavigationFields
              treeChildren {
                ...PageNavigationFields
                treeChildren {
                  ...PageNavigationFields
                }
              }
            }
          }
        }
      }
    }

    page: datoCmsLearnPage(slug: { eq: $slug }) {
      seoMetaTags {
        tags
      }
      title
      metadataSchema
      slug
      content {
        ... on DatoCmsFeature {
          ...Feature
        }
        ... on DatoCmsDuo {
          ...Duo
        }
        ... on DatoCmsSeparator {
          ...Separator
        }
        ... on DatoCmsCallToAction {
          ...CallToAction
        }
        ... on DatoCmsCallToActionBanner {
          ...CallToActionBanner
        }
        ... on DatoCmsImageWithText {
          ...ImageWithText
        }
        ... on DatoCmsShowcaseHero {
          ...ShowcaseHero
        }
        ... on DatoCmsAnimationHero {
          ...AnimationHero
        }
        ... on DatoCmsTextContent {
          ...TextContent
        }
        ... on DatoCmsFeaturedImage {
          ...FeaturedImage
        }
        ... on DatoCmsFeaturedList {
          ...FeaturedList
        }
        ... on DatoCmsJobListing {
          ...JobListing
        }
        ... on DatoCmsStructuredText {
          ...StructuredText
        }
        ... on DatoCmsTermHtmlModule {
          ...TermHtmlModule
        }
      }
    }
  }
`;
