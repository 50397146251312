import React from 'react';
import cx from 'classnames';
import { graphql } from 'gatsby';

import { BACKGROUND_COLOR_TO_CLASS } from '../../tools/utilities/constants';

import { CallToAction } from '../shared/call-to-action';
import { ResponsiveBackgroundImage } from '../shared/responsive-background-image';
import { ImageWithText } from './image-with-text';
import { ContentSection } from '../shared/content-section';
import { List } from '../shared/list';
import { TickList } from '../shared/tick-list';
import { Duo } from './duo';
import { Form } from '../shared/form';
import { Address } from '../shared/address';
import { GithubCards } from '../shared/github-card';
import { TickListText } from '../shared/ticklist-text';

const CONTENT_ID_COMPONENT_MAPPING = {
  image_with_text_child: ImageWithText,
  content_section_child: ContentSection,
  list: List,
  tick_list: TickList,
  duo_child: Duo,
  form: Form,
  address_module: Address,
  repository_card_group: GithubCards,
  ticklist_text: TickListText,
};

const renderChildComponent = (content) => {
  const Component = CONTENT_ID_COMPONENT_MAPPING[content?.model?.apiKey] || null;

  if (!Component) {
    return null;
  }

  // TODO(DEBT-006): Replace process.env with props
  if (process.env.GATSBY_SHOW_MODULE_NAMES === 'true') {
    return (
      <div style={{ borderWidth: '2px', borderColor: 'purple' }}>
        <div style={{ color: 'purple' }}>
          <span>{content?.model?.apiKey}</span>
        </div>
        <Component content={content} />
      </div>
    );
  }

  return <Component content={content} />;
};

const marginDisabled = {
  marginTop: 0,
  marginBottom: 0,
};

const paddingDisabled = {
  paddingTop: 0,
  paddingBottom: 0,
};

export const Feature = ({
  content,
  callToAction,
  backgroundColour,
  backgroundImage,
  mobileBackgroundImage,
  anchorLink,
  enableMargin,
  enablePadding,
}) => {
  const backgroundClassName = cx({
    'bg-no-repeat bg-center bg-cover': backgroundImage,
  });

  // default margin enabled
  const shouldEnableMargin = (enableMargin === null) | enableMargin;
  // default padding disabled
  const shouldEnablePadding = enablePadding === null ? false : enablePadding;

  const id = anchorLink;

  const styleMargin = shouldEnableMargin ? null : marginDisabled;
  const stylePadding = shouldEnablePadding ? null : paddingDisabled;

  return (
    <section
      className={cx(
        `feature cms-module lg:flex lg:justify-center relative ${BACKGROUND_COLOR_TO_CLASS[backgroundColour]} ${backgroundClassName}`,
        {
          'bg-gradient-to-b from-white to-gray-50':
            // eslint-disable-next-line no-underscore-dangle
            content.__typename === 'DatoCmsList' && content.isGradient,
        },
      )}
      id={id}
      style={{
        ...styleMargin,
        ...stylePadding,
      }}
    >
      <ResponsiveBackgroundImage backgroundImage={backgroundImage} mobileBackgroundImage={mobileBackgroundImage} />

      <div>
        <CallToAction content={callToAction} />
        {renderChildComponent(content)}
      </div>
    </section>
  );
};

export const FeatureQueryFragment = graphql`
  fragment Feature on DatoCmsFeature {
    enableMargin
    enablePadding
    anchorLink
    content {
      ... on DatoCmsImageWithTextChild {
        model {
          apiKey
        }
        id
        isImageFixed
        text
        buttons {
          buttons {
            id
            title
            href
            openInNewWindow
            buttonStyle
          }
        }
        superscript
        image {
          url
          height
          customData
        }
        mobileImage {
          url
          height
          customData
        }
      }
      ... on DatoCmsDuoChild {
        leftColumn {
          ... on DatoCmsList {
            model {
              apiKey
            }
            id
            showListAsGrid
            listItems {
              id
              title
              imageIcon {
                url
              }
              contentBody
            }
          }
          ... on DatoCmsContentSectionChild {
            model {
              apiKey
            }
            id
            buttonBar {
              buttons {
                id
                title
                href
                openInNewWindow
                buttonStyle
              }
            }
            content
            image {
              url
            }
          }

          ... on DatoCmsTickList {
            model {
              apiKey
            }
            id
            title
            tickList
          }
        }

        rightColumn {
          ... on DatoCmsList {
            model {
              apiKey
            }
            id
            showListAsGrid
            listItems {
              id
              title
              imageIcon {
                url
              }
              contentBody
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsContentSectionChild {
            model {
              apiKey
            }
            id
            buttonBar {
              buttons {
                id
                title
                href
                openInNewWindow
                buttonStyle
              }
            }
            content
            image {
              url
            }
            logoGrid {
              logo {
                url
                alt
              }
              url
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsTickList {
            model {
              apiKey
            }
            id
            title
            tickList
            model {
              apiKey
            }
          }
        }

        model {
          apiKey
        }
      }
      ... on DatoCmsList {
        model {
          apiKey
        }
        id
        showListAsGrid
        button {
          buttons {
            id
            title
            href
            buttonStyle
            openInNewWindow
          }
        }
        itemsToShow
        isGradient
        listItems {
          id
          title
          imageIcon {
            url
          }
          url
          contentBody
          isArticle
          author
          isGradient
          metadata {
            organisation
            timeToRead
            date
          }
          backgroundColor
          linkToVideo
        }
      }
      ... on DatoCmsRepositoryCardGroup {
        url
        repoCard {
          title
          text
          metadata {
            repoName
            license
          }
          language {
            name
            code
          }
          logo
          url
          model {
            apiKey
          }
        }
        model {
          apiKey
        }
      }
    }
    callToAction {
      title
      superscript
    }
    backgroundColour
    mobileBackgroundImage {
      url
    }
    backgroundImage {
      url
    }
    model {
      apiKey
    }
  }
`;
