import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import config from '../../../config/webConfig';
import type { SeoMetaTagsProps, MetadataSchemaProps } from '../../types/fragments.types';

export const Head = ({
  seoMetaTags,
  metadataSchema,
}: {
  seoMetaTags?: SeoMetaTagsProps;
  metadataSchema: MetadataSchemaProps;
}): JSX.Element => {
  const isDevEnv = config.IS_DEV_ENV;
  const { content: pageTitle } = seoMetaTags?.tags.find((tag: { tagName: string }) => tag?.tagName === 'title') || '';

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#333132" />
      <meta name="msapplication-TileColor" content="#333132" />
      <meta name="theme-color" content="#EAD9E3" />
      <meta name="google-site-verification" content="iHETzz6HbSozkdQ6MYCWCHOsuxrX2xbYAtLvmcTYHCk" />
      <script type="application/ld+json">{metadataSchema}</script>
      <script type="application/ld+json">
        {`[
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "https://learn.mattr.global/",
            "name": "${pageTitle}",
            "alternateName": "MATTR Learn"
          }
        ]`}
      </script>

      {isDevEnv && <meta name="robots" content="noindex" />}
    </>
  );
};
